<template>
  <div class="row">
    <!-- Loop through the statistics data -->
    <div
      class="col-md-3 col-sm-6"
      v-for="(data, index) in statData"
      :key="index"
    >
      <router-link :to="data.url">
        <div class="card" style="border: 1px solid #0f3460; min-height: 150px">
          <div class="card-body p-2">
            <div class="d-flex justify-content-center align-item-center">
              <div class="text-primary mr-4">
                <!-- Display an icon corresponding to the statistic -->
                <i :class="`${data.icon} count-icon-cust-cls`"></i>
              </div>
              <div>
                <!-- Display the title of the statistic -->
                <p class="text-truncate mt-1 mb-1 count-title-cust-cls">
                  {{ data.title }}
                </p>
                <!-- Conditional rendering based on the statistic title -->
                <!-- If the title is 'Users', display the user count -->
                <h4
                  class="mb-0 count-val-cust-cls"
                  v-if="data.title === 'Users'"
                >
                  {{ activeUserData.length + inactiveUserData.length }}
                </h4>
                <!-- If the title is 'Projects', display the project count -->
                <h4
                  class="mb-0 count-val-cust-cls"
                  v-else-if="data.title === 'Projects'"
                >
                  {{ projectsList.length }}
                </h4>
                <!-- If the title is 'Files', display the file count with a loader if loading -->
                <h4
                  class="mb-0 count-val-cust-cls"
                  v-else-if="data.title === 'Files'"
                >
                  <div class="loader custom-loader" v-if="showLoader">
                    <div
                      class="spinner-border text-primary spinner-border-sm"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div v-if="!showLoader">{{ filecount }}</div>
                </h4>
                <!-- For other titles, display the corresponding value -->
                <h4 class="mb-0 count-val-cust-cls" v-else>{{ data.value }}</h4>
              </div>
            </div>
          </div>
          <div
            v-if="data.title !== 'Projects' && data.title !== 'VM`s'"
            class="border-top py-1"
          >
            <div class="text-truncate" v-if="data.title === 'Users'">
              <!-- If the title is 'Users', display the counts and labels for active and inactive users -->
              <span class="text-primary ml-2 font-size-11"
                >{{ data.label1 }}:{{ activeUserData.length }}</span
              >
              <span class="text-secondary font-size-11 mr-2 count-cust-cls2"
                >{{ data.label2 }}:{{ inactiveUserData.length }}</span
              >
            </div>
            <div class="text-truncate" v-else-if="data.title === 'Files'">
              <!-- If the title is 'Files', provide a button to refresh the data -->
              <div
                @click="refreshGetOrgDetails"
                class="refresh-btn text-secondary font-size-11 mr-2 count-cust-cls2"
              >
                <i class="fas fa-sync-alt mr-2"></i>Refresh
              </div>
            </div>
            <div class="text-truncate count-body-cust-cls" v-else></div>
          </div>
        </div>
      </router-link>
    </div>
    <div
      class="col-md-3 col-sm-6 cursor-pointer"
      @click="redirectToCreditDetails"
    >
      <div class="card" style="border: 1px solid #0f3460; min-height: 150px">
        <div class="card-body p-2">
          <div class="d-flex justify-content-center align-items-center">
            <div class="text-primary mr-4">
              <i :class="`mdi mdi-folder-multiple count-icon-cust-cls`"></i>
            </div>
            <div>
              <p class="text-truncate count-title-cust-cls mb-0">Credits</p>
            </div>
          </div>
          <div class="mt-2">
            <p class="text-primary mb-1 ml-0 font-size-11 count-cust-cls1">
              Total: {{ creditsDetails?.totalCredits }}
            </p>
            <p class="text-primary mb-1 ml-0 font-size-11 count-cust-cls1">
              Used: {{ creditsDetails?.usedCredits }}
            </p>
            <p class="text-primary mb-1 ml-0 font-size-11 count-cust-cls1">
              Remaining: {{ creditsDetails?.remainingCredits }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.count-cust-cls1 {
  margin-right: auto;
  margin-left: 5px;
}
.refresh-btn {
  cursor: pointer;
}
.count-cust-cls2 {
  margin-left: auto;
  margin-right: 5px;
}
.count-body-cust-cls {
  padding-bottom: 21px;
}
.count-card-body-cust {
  padding: 0.75rem 1.25rem;
}
.count-icon-cust-cls {
  font-size: 50px !important;
  color: #0f3460 !important;
  top: 5px;
  left: 10px;
  line-height: 50px;
}
.custom-loader {
  height: 3vh;
}
.count-title-cust-cls {
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #0f3460 !important;
}
.count-val-cust-cls {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #0f3460;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
<script>
import { axios } from "@/api/index.js";
import { URL } from "@/api/global.env.js";
export default {
  data() {
    return {
      statData: [
        {
          title: "Users",
          icon: "mdi mdi-account-group",
          value: "25",
          label1: "Active",
          value1: "10",
          label2: "Inactive",
          value2: "15",
          url: "/users",
        },
        {
          title: "Projects",
          icon: "mdi mdi-layers-triple",
          value: "20",
          url: "/github",
        },
        {
          title: "VM`s",
          icon: "mdi mdi-apple-airplay",
          value: "1",
          url: "#",
        },
        // {
        //   title: "Credits",
        //   icon: "mdi mdi-folder-multiple",
        //   value: "0",
        // },
      ],
      projectsList: "",
      activeUserData: "",
      inactiveUserData: "",
      filecount: "",
      storedCount: null,
      gitToken: this.$store.state.Auth.gitToken,
      orgId: this.$store.state.Auth.orgId,
      token: this.$store.state.Auth.token,
      apiURL: process.env.VUE_APP_DATABASEURL,
      showLoader: false,
      creditsDetails: null,
    };
  },
  mounted() {
    if (this.$store.state.Auth.fileCount !== "") {
      this.showLoader = false;
      this.filecount = this.$store.state.Auth.fileCount;
    } else {
      this.getOrgDetails();
    }
    if (this.$store.state.Auth.projectsList.length) {
      this.projectsList = this.$store.state.Auth.projectsList;
    } else {
      this.getProjectDetails();
    }
    if (this.$store.state.Auth.activeUserData.length) {
      this.activeUserData = this.$store.state.Auth.activeUserData;
    } else {
      this.getActiveUserData();
    }
    if (this.$store.state.Auth.inActiveUserData.length) {
      this.inactiveUserData = this.$store.state.Auth.inActiveUserData;
    } else {
      this.getInactiveUserData();
    }
    if (this.$store.state.Auth.creditsDetails) {
      this.creditsDetails = this.$store.state.Auth.creditsDetails;
    } else {
      this.creditPointsReport();
    }
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    getProjectDetails() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`project/byorgid?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          if (response.data) {
            this.projectsList = response.data;
            this.$store.commit("setProjectList", this.projectsList);
          } else {
            this.messageToast("Error", "danger", "No project details found");
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while fetching project details"
          );
        });
    },
    getActiveUserData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`orguser/active/profile?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          if (response.data) {
            this.activeUserData = response.data;
            this.$store.commit("setActiveUserCount", this.activeUserData);
            loader.hide();
          } else {
            this.messageToast("Error", "danger", "No active user data found");
            loader.hide();
          }
        })
        .then(() => {
          this.getInactiveUserData();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while fetching active user data"
          );
        });
    },
    getInactiveUserData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`orguser/inactive/profile?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          if (response.data) {
            this.inactiveUserData = response.data;
            this.$store.commit("setInactiveUserCount", this.inactiveUserData);
          } else {
            this.messageToast("Error", "danger", "No inactive user data found");
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while fetching inactive user data"
          );
        });
    },
    refreshGetOrgDetails() {
      this.showLoader = true;
      let gitToken = this.$store.state.Auth.gitToken;
      if (!gitToken) {
        return;
      }
      let orgId = this.$store.state.Auth.orgId;
      let token = this.$store.state.Auth.token;
      axios({
        method: "get",
        url: `${URL}api/orgadmin/github/projectfilecount?orgId=${orgId}`,
        headers: {
          gitToken: gitToken,
          Authorization: `Bearer ${token}`,
          userId: this.$store.state.Auth.userId,
          orgId: this.$store.state.Auth.orgId,
        },
      })
        .then((res) => {
          if (res.data) {
            this.filecount = res.data.fileCount;
            this.$store.commit("setFileCount", this.filecount);
          } else {
            this.messageToast(
              "Error",
              "danger",
              "No organization details found"
            );
          }
        })
        .catch(() => {
          this.showLoader = false;
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while fetching organization details"
          );
        });
    },
    getOrgDetails() {
      this.$store.getters.client
        .get(
          `/serveradmin/organization/byorgid?orgId=${this.$store.state.Auth.orgId}`
        )
        .then((res) => {
          if (res.data.length > 0) {
            this.filecount = res.data[0].fileCount;
            this.$store.commit("setFileCount", this.filecount);
          } else {
            this.messageToast(
              "Error",
              "danger",
              "No organization details found"
            );
          }
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
          this.messageToast(
            "Error",
            "error",
            "An error occurred while fetching organization details"
          );
        });
    },
    creditPointsReport() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`/orgadmin/credits-info`)
        .then((response) => {
          console.log("credits-info: ", response);
          if (response.data) {
            this.creditsDetails = response.data.data;
            this.$store.commit("setCreditsDetails", this.creditsDetails);
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while fetching credit details"
          );
        });
    },
    redirectToCreditDetails() {
      this.$router.push({
        path: `/credit-details`,
      });
    },
  },
};
</script>
