<template>
  <div>
    <!-- Header with Tabs -->
    <div class="cmh-header">
      <ActiveUsers />
    </div>
  </div>
</template>
<script>
import ActiveUsers from "./active.vue";
export default {
  components: {
    ActiveUsers,
  },
  data() {
    return {};
  },
  created() {
    if (!this.$store.state.Auth.gitToken) {
      this.$router.push({
        name: "Projects",
      });
    }
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
  },
};
</script>
<style scoped>
.cmh-tab-title {
  font-weight: 900;
  color: blue;
}
</style>
