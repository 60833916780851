<template>
  <div>
    <!-- Row for Pagination and Search -->
    <div class="row">
      <div class="col-12">
        <div class="row py-3">
          <!-- Per Page Dropdown for Entries -->
          <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-flex align-items-center"
                ><span class="text-nowrap">Show entries</span>
                <div class="col-md-3">
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select>
                </div>
              </label>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <!-- Buefy Table Component -->
          <b-table
            :items="creditsDetails?.creditTransactions"
            :fields="colfields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            ref="activeUserTable"
            show-empty
          >
          </b-table>
        </div>
        <!-- Pagination and Row Count -->
        <div class="row">
          <!-- Row Count -->
          <div
            class="dataTables_paginate paging_simple_numbers col justify-content-center row-count-cust-cls"
          ></div>
          <!-- Pagination -->
          <div class="row-pagination-cust-cls">
            <ul class="pagination-rounded mb-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      creditsDetails: null,
      colfields: [
        {
          key: "docMigrationType.text",
          label: "docMigration Type",
        },
        {
          key: "fileName",
          label: "File Name",
          sortable: false,
        },

        {
          key: "convertedAt",
          label: "Migration Date",
          formatter: (value, key, item) =>
            moment(item.convertedAt).format("DD-MM-YYYY hh:mm A"),
        },
        {
          key: "orgUserId.username",
          label: "Username",
        },
        {
          key: "pagesConverted",
          label: "Credits used",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.creditsDetails?.creditTransactions.length;
    },
  },
  mounted() {
    if (this.$store.state.Auth.creditsDetails) {
      this.creditsDetails = this.$store.state.Auth.creditsDetails;
      this.totalRows = this.creditsDetails.creditTransactions.length;
    } else {
      this.creditPointsReport();
    }
  },
  methods: {
    moment,
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    creditPointsReport() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`/orgadmin/credits-info`)
        .then((response) => {
          console.log("credits-info: ", response);
          if (response.data) {
            this.creditsDetails = response.data.data;
            this.creditsDetails.creditTransactions =
              response.data.data.creditTransactions;
            this.totalRows = this.creditsDetails.creditTransactions.length;

            this.$store.commit("setCreditsDetails", this.creditsDetails);
          }
          loader.hide();
        })
        .catch(() => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            "An error occurred while fetching credit details"
          );
        });
    },
  },
};
</script>
<style>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }
  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
