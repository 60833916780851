import BootstrapVue from "bootstrap-vue";
import Swal from "sweetalert2";
import Vue from "vue";
import { axios } from "./api";
Vue.use(BootstrapVue);
const vm = new Vue({});

export function handleApiError(error) {
  if (error.response.status === 409) {
    console.log(error);
    localStorage.clear();
    Swal.fire({
      icon: "error",
      title: error.response.data.message || "Your session has expired.",
    }).then(async (response) => {
      const objKeys = Object.keys(response);
      if (objKeys.includes("isConfirmed") && objKeys.includes("isDismissed")) {
        if (response.isConfirmed === true || response.isDismissed === true) {
          await axios.post(
            `${URL}api/logout`,
            {},
            {
              headers: {
                userid: error.response.data.userId,
                // sessionId: error.response.data.data.sessionId,
              },
            }
          );
          window.location.href = "/";
          return Promise.resolve();
        }
      }
    });
  }

  // if user is unauthenticated
  if (error.response.status === 401) {
    vm.$bvToast.toast("Please login to access the application.", {
      title: "Unauthorized request.",
      variant: "danger",
      solid: true,
    });
    return Promise.resolve();
  }

  if (document.getElementsByClassName("toast").length) {
    return;
  }

  if (error.response.data.message == "Invalid Token") {
    localStorage.clear();
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Your session has expired. Please log in again to continue accessing the metR. ",
    }).then(() => {
      window.location.href = "/";
    });
  }
  if (
    error.response.data.message ==
    "The current subscription of your organization has been expired !"
  ) {
    localStorage.clear();
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "The current subscription of your organization has been expired. Please ask your organization to renew the subscription plan to use our services again. Thank you!",
    }).then(() => {
      window.location.href = "/";
    });
  }
}
