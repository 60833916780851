<template>
  <div>
    <!-- PageHeader component with icon, title, and items as props -->
    <PageHeader
      :icon="'mdi mdi-account-multiple h3'"
      :title="title"
      :items="items"
    />
    <!-- Details component -->
    <Details />
  </div>
</template>

<script>
import PageHeader from "../../../components/pageheader.vue";
import Details from "./components/details.vue";
import { eventBus } from "../../../main";
export default {
  components: {
    PageHeader,
    Details,
  },
  data() {
    return {
      title: "Users",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Users",
          active: true,
        },
      ],
    };
  },
  mounted() {
    eventBus.$emit("update-sidebar", "menuitems.users.text");
  },
};
</script>
