<template>
  <div>
    <!-- Header section with an icon, title, and notification dropdown -->
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="card-body">
            <div class="d-flex justify-content-end mb-3">
              <button
                class="btn btn-sm btn-danger mr-3"
                @click="deleteSelectedUsers"
                v-if="selectedUsersForDelete.length > 0"
              >
                Delete collaborators
              </button>
              <button
                class="btn btn-sm btn-primary"
                @click="openAddUserRoleModal"
              >
                Add collaborators
              </button>
            </div>
            <div class="row justify-content-between align-items-center">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-flex align-items-center"
                    ><span class="text-nowrap">Show entries</span>
                    <div class="col-md-3">
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select>
                    </div>
                  </label>
                </div>
              </div>
              <div
                class="col-sm-12 col-md-6 d-flex justify-content-end align-items-center"
              >
                <div class="dataTables_filter text-md-right">
                  <!-- Input for searching Github usernames -->
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search by Github Username"
                    class="form-control-sm ml-2"
                  ></b-form-input>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table for displaying user data -->
            <div class="table-responsive mb-0">
              <b-table
                :items="ProjectUserlist"
                :fields="colfields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                :filter-function="filterTable"
                ref="projectUserTable"
                show-empty
                small
              >
                <!-- Add checkbox in the first column -->
                <template #cell(select)="row">
                  <b-form-checkbox
                    v-model="selectedUsersForDelete"
                    :value="row.item.githubUsername"
                    :id="'checkbox-' + row.item.githubUsername"
                    aria-label="Select user"
                  />
                </template>
                <!-- invitation accepted or not -->
                <template #cell(invitationStatus)="row">
                  <div
                    v-if="
                      !row.item.acceptInvitation &&
                      moment().isAfter(row.item.invitationExpiresIn)
                    "
                  >
                    <div class="badge badge-soft-secondary font-size-12 py-2">
                      Expired
                    </div>
                    <button
                      class="btn btn-sm btn-primary p-1 ml-3"
                      @click="resendInvitation(row.item.id)"
                    >
                      Resend
                    </button>
                  </div>
                  <div
                    v-else
                    class="badge font-size-12 py-2"
                    :class="{
                      'badge-soft-secondary':
                        `${row.item.acceptInvitation}` === 'false',
                      'badge-soft-primary':
                        `${row.item.acceptInvitation}` === 'true',
                    }"
                  >
                    {{
                      row.item.acceptInvitation === true
                        ? "Accepted"
                        : "Pending"
                    }}
                  </div>
                </template>
              </b-table>
            </div>
            <div class="d-flex justify-content-end">
              <div class="row-pagination-cust-cls">
                <ul class="pagination-rounded mb-0">
                  <!-- Pagination controls -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="my-4 text-center">
        <b-modal
          id="modal-standard"
          ref="addUserRoleModel"
          title="Add Collaborators"
          title-class="font-18"
          hide-footer
        >
          <div class="row">
            <div class="col-lg-12">
              <form
                class="form-horizontal"
                @submit.prevent="addCollaboratorsToProject"
                ref="formAddUserRole"
              >
                <div class="form-group">
                  <label for="githubUsername">Project name</label>
                  <input
                    v-model="projectDataTemp.projectName"
                    name="githubUsername"
                    type="text"
                    class="form-control disable-cust-cls"
                    id="githubUsername"
                    placeholder="Project name"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label for="users">Users</label>
                  <multiselect
                    name="updatef_projects"
                    id="updatef_projects"
                    v-model="usernameList"
                    tag-placeholder="Add this new usernames"
                    placeholder="Enter usernames"
                    label="text"
                    track-by="value"
                    :multiple="true"
                    :max-height="150"
                    :taggable="true"
                    @tag="addUserNameToList"
                    :options="[]"
                  />
                  <p class="text-danger text-sm" v-show="errors.has('users')">
                    The users field is required
                  </p>
                </div>

                <div class="text-center">
                  <!-- Buttons to submit or cancel the form -->
                  <button
                    class="btn btn-sm btn-primary w-md waves-effect waves-light"
                    type="submit"
                  >
                    Submit
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="reset"
                    class="btn btn-sm btn-light w-md waves-effect waves-light"
                    v-on:click="closeAddUserRoleModel()"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../api/global.env";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  components: {
    Multiselect,
  },
  props: ["projectData"],
  data() {
    return {
      ProjectUserlist: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "published_at",
      sortDesc: true,
      colfields: [
        {
          key: "select",
          label: "",
        },
        {
          key: "githubUsername",
          label: "Github Username",
          sortable: false,
        },
        {
          key: "githubEmail",
          label: "Email",
          sortable: false,
        },
        {
          key: "invitationStatus",
          label: "Invitation Status",
          sortable: false,
        },
      ],
      usersList: [
        {
          id: "1",
          name: "Select Users",
        },
        {
          id: "2",
          name: "User 01",
        },
      ],

      projectDataTemp: "",
      id: CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      ),

      usernameList: [],
      selectedUsersForDelete: [],
    };
  },
  created() {
    this.getUserList();
    this.getProjectDetails();
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.ProjectUserlist.length;
    },
    _() {
      return _;
    },
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.ProjectUserlist.length;
  },
  methods: {
    moment,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    async getUserList() {
      this.$store.getters.client
        .get(`/projectuser/byprojectid?projectId=${this.id}`)
        .then((response) => {
          console.log("📢[user.vue:283]: response: ", response.data);
          this.ProjectUserlist = response.data;
          this.totalRows = this.ProjectUserlist.length;
        });
    },

    openAddUserRoleModal() {
      this.$refs["addUserRoleModel"].show();
    },
    closeAddUserRoleModel() {
      this.usernameList = [];
      this.$refs["addUserRoleModel"].hide();
    },

    addUserNameToList(newUsername) {
      if (newUsername && !this.usernameList.includes(newUsername)) {
        this.usernameList.push({ text: newUsername, value: newUsername });
      }
    },
    addCollaboratorsToProject() {
      let data = {
        repoName: this.projectDataTemp.projectName,
        collaborators: this.usernameList.map((username) => username.value),
      };
      console.log("📢[user.vue:385]: data: ", data);
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .post(`/project/repo/collaborators/add`, data)
        .then((res) => {
          console.log("📢[user.vue:340]: res: ", res.data);
          if (res.data.message && res.data.results) {
            this.messageToast(
              "Partially added",
              "warning",
              `${res.data.message}\n${res.data.results}`
            );
          } else if (res.data.results) {
            this.messageToast(
              "Collaborators not added",
              "danger",
              res.data.results
            );
          } else if (res.data.message) {
            this.messageToast(
              "Collaborators added successfully",
              "success",
              res.data.message
            );
          }
          this.$store.state.sgitrepodata = [];
          // Resetting Values

          loader.hide();
          this.usernameList = [];
          this.$refs["addUserRoleModel"].hide();
          this.getUserList();
        })
        .catch((error) => {
          console.log("📢[user.vue:369]: error: ", error.response);
          this.messageToast(
            "Error",
            "danger",
            error.response.data.results || error.response.data.message
          );
          loader.hide();
        });
    },

    deleteSelectedUsers() {
      let data = {
        repoName: this.projectDataTemp.projectName,
        collaborators: this.selectedUsersForDelete,
      };
      console.log("📢[user.vue:385]: data: ", data);

      Swal.fire({
        title: "Remove Collaborators",
        text: "Do you want to remove these collaborators?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Remove",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          this.$store.getters.client
            .delete(`/project/repo/collaborators/remove`, { data })
            .then((res) => {
              if (res.data.message) {
                this.messageToast(
                  "Collaborators removed successfully",
                  "success",
                  res.data.message
                );
              }
              loader.hide();
              this.$store.state.sgitrepodata = [];
              this.selectedUsersForDelete = [];
              this.getUserList();
            })
            .catch((error) => {
              this.messageToast("Error", "danger", error.response.data.message);
              loader.hide();
            });
        }
      });
    },

    resendInvitation(id) {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .patch(
          `/project/repo/collaborators/resend-invitations?projectUserId=${id}`
        )
        .then((res) => {
          if (res.data.message) {
            this.messageToast("Invitation send", "success", res.data.message);
          }
          this.$store.state.sgitrepodata = [];
          // Resetting Values

          loader.hide();
          this.usernameList = [];
          this.getUserList();
        })
        .catch((error) => {
          this.messageToast("Error", "danger", error.response.data.message);
          loader.hide();
        });
    },
    async getProjectDetails() {
      this.$store.getters.client
        .get(`project/byprojectid?projectId=${this.id}`)
        .then((res) => {
          this.projectDataTemp = res.data[0];
        })
        .then(() => {})
        .catch(() => {});
    },
    filterTable(row, filter) {
      const filterUpperCase = filter.toUpperCase();
      const hasNameMatch = row.githubUsername
        .toUpperCase()
        .includes(filterUpperCase);
      return hasNameMatch;
    },
  },
};
</script>

<style scoped>
label {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.table th {
  font-weight: 400 !important;
}

.notification-item-customcls .media:hover {
  background-color: unset !important;
}

.notification-item-customcls .media {
  border-bottom: #f1f5f7 2px solid;
}

.notification-item-customcls:last-child .media {
  border-bottom: none;
}

.disable-cust-cls {
  background: #e7e7e8 !important;
  color: #838588 !important;
}

.custom-select:disabled {
  color: #838588 !important;
  background: #e7e7e8 !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .cust-simplebar-wrap {
    width: 265px;
  }

  .row-count-cust-cls {
    margin-bottom: 10px;
  }

  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .cust-simplebar-wrap {
    width: 265px;
  }

  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .cust-simplebar-wrap {
    width: auto;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .cust-simplebar-wrap {
    width: auto;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .cust-simplebar-wrap {
    width: auto;
  }
}
</style>
