<template>
  <div>
    <!-- Main Card -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Data Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="adminData"
                :fields="colfields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :filter-function="filterTable"
                ref="adminTable"
                show-empty
              >
                <!-- Custom cell rendering for isEmailVerified -->
                <template #cell(isEmailVerified)="row">
                  <div
                    class="badge font-size-12"
                    :class="{
                      'badge-soft-secondary':
                        `${row.item.isEmailVerified}` === 'false',
                      'badge-soft-primary':
                        `${row.item.isEmailVerified}` === 'true',
                    }"
                  >
                    {{
                      row.item.isEmailVerified === true
                        ? "Verified"
                        : "Not Verified"
                    }}
                  </div>
                </template>
                <!-- Custom cell rendering for status -->
                <template #cell(status)="row">
                  <div
                    class="badge font-size-12"
                    :class="{
                      'badge-soft-secondary':
                        `${row.item.isActive}` === 'false',
                      'badge-soft-primary': `${row.item.isActive}` === 'true',
                    }"
                  >
                    {{ row.item.isActive === true ? "Active" : "Deactive" }}
                  </div>
                </template>
                <!-- Custom cell rendering for createdAt -->
                <template #cell(createdAt)="row">
                  {{ moment(row.item.createdAt).format("YYYY-MM-DD") }}
                </template>
                <!-- Custom cell rendering for action dropdown -->
                <template #cell(action)="row">
                  <b-dropdown
                    right
                    toggle-class="arrow-none card-drop"
                    class="float-right"
                    variant="white"
                  >
                    <template
                      v-slot:button-content
                      v-if="profileData[0].isSuperAdmin"
                    >
                      <i class="mdi mdi-dots-vertical"></i>
                    </template>

                    <!-- item for changing password -->
                    <b-dropdown-item @click="openChangePasswordModel(row)"
                      >Change Password</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Change Password Modal -->
    <div>
      <div class="my-4 text-center">
        <b-modal
          id="modal-standard"
          ref="adminChangePasswordModal"
          title="Change Password"
          title-class="font-18"
          hide-footer
        >
          <div class="row">
            <div class="col-lg-12">
              <form
                class="form-horizontal"
                @submit.prevent="adminchangePassword"
                ref="formAdminChangePasswords"
              >
                <div class="form-group">
                  <label for="admin_password">New Password</label>
                  <div class="input-group">
                    <input
                      v-validate="'required|verify_password'"
                      v-model="admin_password"
                      name="admin_password"
                      :type="showPasswordNew ? 'text' : 'password'"
                      class="form-control"
                      id="admin_password"
                      placeholder="Enter new password"
                      :class="{
                        'is-invalid': submitted && $v.admin_password.$error,
                      }"
                      ref="admin_password"
                    />
                    <div class="input-group-append">
                      <div
                        class="btn btn-outline-secondary"
                        type="button"
                        @click="toggleNewPasswordVisibility()"
                      >
                        <i :class="eyeIconClassNewpass"></i>
                      </div>
                    </div>
                  </div>
                  <p
                    class="text-danger text-sm"
                    v-show="
                      errors.has('admin_password') &&
                      _.find(errors.items, { field: 'admin_password' }).rule ==
                        'required'
                    "
                  >
                    <span>The password field is required</span>
                  </p>
                  <p
                    class="text-danger text-sm"
                    v-show="
                      errors.has('admin_password') &&
                      _.find(errors.items, { field: 'admin_password' }).rule ==
                        'verify_password'
                    "
                  >
                    {{ errors.first("admin_password") }}
                  </p>
                </div>
                <div class="form-group">
                  <label for="admin_password_confirmation"
                    >Confirm Password</label
                  >
                  <div class="input-group">
                    <input
                      v-validate="'required|confirmed:admin_password'"
                      v-model="admin_password_confirmation"
                      name="admin_password_confirmation"
                      :type="showPasswordCfrm ? 'text' : 'password'"
                      class="form-control"
                      id="admin_password_confirmation"
                      placeholder="Enter password"
                      :class="{
                        'is-invalid':
                          submitted && $v.admin_password_confirmation.$error,
                      }"
                    />
                    <div class="input-group-append">
                      <div
                        class="btn btn-outline-secondary"
                        type="button"
                        @click="toggleCfrmPasswordVisibility()"
                      >
                        <i :class="eyeIconClassCfrm"></i>
                      </div>
                    </div>
                  </div>
                  <p
                    class="text-danger text-sm"
                    v-show="errors.has('admin_password_confirmation')"
                  >
                    The password confirmation field does not match
                  </p>
                </div>
                <div class="text-center">
                  <button
                    class="btn btn-primary btn-sm w-md waves-effect waves-light"
                    type="submit"
                  >
                    Save
                  </button>
                  &nbsp;&nbsp;
                  <button
                    class="btn btn-sm btn-secondary w-md waves-effect waves-light"
                    v-on:click="closeChangePasswordModel()"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import VeeValidate from "vee-validate";
import Swal from "sweetalert2";
import { axios } from "@/api/index.js";
import _ from "lodash";
import { logoutApplication } from "../../../../api/logout";
export default {
  data() {
    return {
      adminData: [],
      totalRows: 1,
      currentPage: 1,
      profileData: null,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      colfields: [
        {
          key: "name",
          label: "Name",
          sortable: false,
        },
        {
          key: "githubUsername",
          label: "Github Username",
          sortable: false,
        },
        {
          key: "email",
          label: "Email",
          sortable: false,
        },
        {
          key: "contact",
          label: "Contact",
          sortable: false,
        },
        {
          key: "isEmailVerified",
          label: "Email Verified",
          sortable: false,
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
        },
        {
          key: "action",
          label: "",
          sortable: false,
        },
      ],
      orgName: "",
      admin_password: null,
      admin_password_confirmation: null,
      error: null,
      submitted: false,
      adminPsswordTempData: "",
      showPasswordNew: false,
      showPasswordCfrm: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.adminData.length;
    },
    _() {
      return _;
    },
    eyeIconClassNewpass() {
      return this.showPasswordNew ? "mdi mdi-eye-off" : "mdi mdi-eye";
    },
    eyeIconClassCfrm() {
      return this.showPasswordCfrm ? "mdi mdi-eye-off" : "mdi mdi-eye";
    },
  },
  created() {
    if (!this.$store.state.Auth.gitToken) {
      this.$router.push({
        name: "Projects",
      });
    }
    if (this.$store.state.Auth.adminData.length) {
      this.adminData = this.$store.state.Auth.adminData;
    } else {
      this.getAdminData();
    }

    if (Object.keys(this.$store.state.Auth.profileData).length) {
      this.profileData = this.$store.state.Auth.profileData.data;
    } else {
      this.getProfileData();
    }
    this.getOrgDetails();
    VeeValidate.Validator.extend("verify_password", {
      getMessage: () =>
        `The password must contain at least: minimum 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
      validate: (value) => {
        var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
        );
        return strongRegex.test(value);
      },
    });
  },
  methods: {
    moment,
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    toggleNewPasswordVisibility() {
      this.showPasswordNew = !this.showPasswordNew;
    },
    toggleCfrmPasswordVisibility() {
      this.showPasswordCfrm = !this.showPasswordCfrm;
    },
    getAdminData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`orgadmin/byorgid?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          if (response && response.data) {
            // Check if data is available in the response
            this.adminData = response.data;

            this.$store.commit("setAdminData", this.adminData);
          } else {
            // Handle the case where response data is empty or undefined
            this.messageToast("Error", "danger", "No admin data found");
          }
        })
        .catch((error) => {
          // Handle the error and display an error message using a message toast
          this.messageToast(
            "Error",
            "danger",
            error.response ? error.response.data.message : "An error occurred"
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    filterTable(row, filter) {
      const filterUpperCase = filter.toUpperCase();
      const hasNameMatch = row.name.toUpperCase().startsWith(filterUpperCase);
      return hasNameMatch;
    },
    async getOrgDetails() {
      this.$store.getters.client
        .get(
          `/serveradmin/organization/byorgid?orgId=${this.$store.state.Auth.orgId}`
        )
        .then((res) => {
          if (res && res.data && res.data.length > 0) {
            // Check if response, response.data, and data length are available
            this.orgName = res.data[0].name;
          } else {
            // Handle the case where response, response.data, or data length is empty or undefined
            this.messageToast(
              "Error",
              "danger",
              "No organization details found"
            );
          }
        })
        .catch((error) => {
          // Handle the error and display an error message using a message toast
          this.messageToast(
            "Error",
            "danger",
            error.response ? error.response.data.message : "An error occurred"
          );
        });
    },
    getProfileData() {
      let loader = this.$loading.show({
        loader: "dots",
      });

      this.$store.getters.client
        .get(`orgadmin/byid?userId=${this.$store.state.Auth.userId}`)
        .then((response) => {
          this.profileData = response.data;

          this.$store.commit("setProfileData", response);

          loader.hide();
        })
        .catch((err) => {
          loader.hide();

          this.messageToast(
            "Error",
            "danger",
            `Error on fetching profile data: ${err.response.data.message}`
          );
        });
    },
    // Deactivate admin by Id
    deactive(data) {
      this.confirmAction(
        "Are you sure?",
        "You want to deactivate this admin!",
        () => this.performAdminAction(data.item.id, false),
        "Admin has been deactivated."
      );
    },
    // Activate admin by Id
    active(data) {
      this.confirmAction(
        "Are you sure?",
        "You want to activate this admin!",
        () => this.performAdminAction(data.item.id, true),
        "Admin has been activated."
      );
    },
    confirmAction(title, text, actionFunction, successMessage) {
      Swal.fire({
        title: title,
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: `Yes`,
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          actionFunction()
            .then(() => {
              this.handleAdminActionSuccess(loader, successMessage);
            })
            .catch((error) => {
              loader.hide();
              this.handleAdminActionError(error);
            });
        }
      });
    },
    performAdminAction(adminId, isActive) {
      return this.$store.getters.client.put(`orgadmin/activeinactive/profile`, {
        orgAdminId: adminId,
        isActive: isActive,
      });
    },
    handleAdminActionSuccess(loader, message) {
      this.getAdminData();
      this.$refs.adminTable.refresh();
      this.$refs["adminTable"].refresh();
      loader.hide();
      Swal.fire("Success", message, "success");
    },
    handleAdminActionError(error) {
      if (error.response) {
        if (
          error.response.data.message ===
          "Another device has login with your account!"
        ) {
          this.handleLogout("Another device has logged in with your account!");
        } else if (error.response.data.message === "Invalid Token") {
          this.handleLogout(
            "Your session has expired. Please log in again to continue accessing metR."
          );
        } else if (
          error.response.data.message ===
          "The current subscription of your organization has been expired !"
        ) {
          this.handleLogout(
            "The current subscription of your organization has been expired. Please ask your organization to renew the subscription plan to use our services again. Thank you!"
          );
        }
      } else {
        this.messageToast(
          "Error",
          "danger",
          "An error occurred while performing the action."
        );
      }
    },
    async handleLogout(message) {
      localStorage.clear();
      await axios.post(
        `${URL}api/logout`,
        {},
        {
          headers: { userid: this.$store.state.Auth.userId },
        }
      );
      logoutApplication(this.$store.state.Auth.userId);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
      }).then(() => {
        window.location.href = "/";
      });
    },
    openChangePasswordModel(data) {
      this.adminPsswordTempData = data;
      this.$refs["adminChangePasswordModal"].show();
      this.showPasswordNew = false;
      this.showPasswordCfrm = false;
    },
    closeChangePasswordModel() {
      // Resetting Values
      this.showPasswordNew = false;
      this.showPasswordCfrm = false;
      this.admin_password = this.admin_password_confirmation = "";
      this.$refs.formAdminChangePasswords.reset();
      this.$refs["adminChangePasswordModal"].hide();
    },
    adminchangePassword() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          this.$store.getters.client
            .put(`/orgadmin/changepassword/byadminid`, {
              orgAdminId: this.adminPsswordTempData.item.id,
              password: this.admin_password,
            })
            .then(() => {
              this.handlePasswordChangeSuccess(loader);
            })
            .catch((error) => {
              this.handlePasswordChangeError(error, loader);
            });
        }
      });
    },
    handlePasswordChangeSuccess(loader) {
      // Resetting Values
      this.admin_password = this.admin_password_confirmation = "";
      this.$refs.formAdminChangePasswords.reset();
      this.messageToast("Success", "success", "Password reset successfully");
      loader.hide();
      this.$refs["adminChangePasswordModal"].hide();
      this.adminPsswordTempData = null; // Assuming you want to clear the temp data
    },
    handlePasswordChangeError(error, loader) {
      if (error.response) {
        this.messageToast("Error", "danger", error.response.data.message);
      } else {
        this.messageToast(
          "Error",
          "danger",
          "An error occurred while changing the password."
        );
      }
      loader.hide();
    },
  },
};
</script>
<style>
.dropdown-menu.dropdown-menu-right.show {
  transform: translate3d(0px, -49px, 0px) !important;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }
  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
