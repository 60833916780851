import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import VeeValidate from "vee-validate";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import vco from "v-click-outside";
import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";
import "@/assets/scss/app.scss";
import ToggleButton from "vue-js-toggle-button";

require("dotenv").config();
Vue.config.productionTip = false;
Vue.use(Loading);
Vue.use(BootstrapVue);
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: "veeFields",
});
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.component("apexchart", VueApexCharts);
Vue.use(VueTelInput);
Vue.use(ToggleButton);
export const eventBus = new Vue();
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
