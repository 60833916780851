<template>
  <div>
    <!-- Container for the component -->
    <div class="card">
      <div class="card-body">
        <form
          class="form-horizontal"
          @submit.prevent="updateUser"
          ref="formUpdateUser"
        >
          <!-- Form fields for updating user information -->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_username"
                  >Username <span class="text-danger">*</span></label
                >
                <!-- Input field for updating username -->
                <input
                  v-validate="'required'"
                  v-model="formData.username"
                  name="updatef_username"
                  type="text"
                  class="form-control"
                  id="updatef_username"
                  placeholder="Enter username"
                  :class="{
                    'is-invalid': submitted && $v.updatef_username.$error,
                  }"
                />
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_username')"
                >
                  The username field is required
                </p>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_collaborators"
                  >Collaborators <span class="text-danger">*</span></label
                >
                <!-- Dropdown for selecting collaborators -->
                <b-form-select
                  :options="optionsCollaboratorlist"
                  v-validate="'required'"
                  v-model="selectedCollaborators"
                  name="updatef_collaborators"
                  type="text"
                  class="form-control"
                  id="updatef_collaborators"
                  placeholder="Select collaborators"
                  :class="{
                    'is-invalid': submitted && $v.updatef_collaborators.$error,
                  }"
                  disabled
                ></b-form-select>
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_collaborators')"
                >
                  The Collaborators field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_projects"
                  >Projects <span class="text-danger">*</span></label
                >
                <!-- Multiselect component for selecting multiple projects -->
                <!-- v-validate="'required'" -->
                <multiselect
                  name="updatef_projects"
                  id="updatef_projects"
                  :class="{
                    'is-invalid': submitted && $v.addf_projects.$error,
                  }"
                  v-model="seletedProject"
                  tag-placeholder="Add this as a new tag"
                  placeholder="Please select a project"
                  label="text"
                  track-by="value"
                  :options="optionsProjectlist"
                  :multiple="true"
                  :max-height="150"
                >
                  <template #selection="{ values, search, isOpen }">
                    <span
                      class="multiselect__single"
                      v-if="values.length"
                      v-show="!isOpen"
                      >{{ values.length }} project selected</span
                    >
                  </template>
                </multiselect>
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_projects')"
                >
                  The projects field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_email"
                  >Email Address <span class="text-danger">*</span></label
                >
                <!-- Input field for updating email address -->
                <input
                  v-validate="'required'"
                  v-model="formData.email"
                  name="updatef_email"
                  type="text"
                  class="form-control"
                  id="updatef_email"
                  placeholder="Enter email"
                  :class="{
                    'is-invalid': submitted && $v.updatef_email.$error,
                  }"
                />
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_email')"
                >
                  The email field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_githubUsername"
                  >Github Username <span class="text-danger">*</span></label
                >
                <!-- Input field for updating GitHub username -->
                <input
                  v-validate="'required'"
                  v-model="formData.githubUsername"
                  name="updatef_githubUsername"
                  type="text"
                  class="form-control disable-cust-cls"
                  id="updatef_githubUsername"
                  placeholder="Enter GitHub Username"
                  :class="{
                    'is-invalid': submitted && $v.updatef_githubUsername.$error,
                  }"
                  disabled
                />
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_githubUsername')"
                >
                  The GitHub username field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="updatef_githubEmail"
                  >GitHub Email Address
                  <span class="text-danger">*</span></label
                >
                <!-- Input field for updating GitHub email address -->
                <input
                  v-validate="'required'"
                  v-model="formData.githubEmail"
                  name="updatef_githubEmail"
                  type="text"
                  class="form-control"
                  id="updatef_githubEmail"
                  placeholder="Enter GitHub email"
                  :class="{
                    'is-invalid': submitted && $v.updatef_githubEmail.$error,
                  }"
                />
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('updatef_githubEmail')"
                >
                  The GitHub email address field is required
                </p>
              </div>
            </div>
            <div class="col-md-6" v-if="!initialUsername">
              <!-- Password Input -->
              <div class="form-group">
                <label for="addf_password"
                  >Password <span class="text-danger">*</span></label
                >
                <div class="input-group">
                  <!-- Input field for Password with toggle button -->
                  <input
                    autocomplete="new-password"
                    v-validate="'required|verify_password'"
                    v-model="password"
                    name="addf_password"
                    :type="showPasswordCfrm ? 'text' : 'password'"
                    class="form-control"
                    id="addf_password"
                    placeholder="Enter password"
                    :class="{
                      'is-invalid': submitted && $v.addf_password.$error,
                    }"
                    ref="addf_password"
                  />
                  <!-- Password visibility toggle button -->
                  <div class="input-group-append">
                    <div
                      class="btn btn-outline-light"
                      type="button"
                      @click="toggleCfrmPasswordVisibility()"
                    >
                      <i :class="eyeIconClassCfrm"></i>
                    </div>
                  </div>
                </div>
                <!-- Validation messages for Password -->
                <p
                  class="text-danger text-sm"
                  v-show="
                    errors.has('addf_password') &&
                    _.find(errors.items, { field: 'addf_password' }).rule ==
                      'required'
                  "
                >
                  <span>The password field is required</span>
                </p>
                <p
                  class="text-danger text-sm"
                  v-show="
                    errors.has('addf_password') &&
                    _.find(errors.items, { field: 'addf_password' }).rule ==
                      'verify_password'
                  "
                >
                  {{ errors.first("addf_password") }}
                </p>
              </div>
            </div>
            <div class="col-md-6" v-if="isUserDataFetched">
              <div class="form-group">
                <label class="mr-3"
                  >View remaining credits
                  <span class="text-danger">*</span></label
                >
                <toggle-button
                  class="mt-2"
                  :value="canViewCreditPoints"
                  @change="canViewCreditPoints = !canViewCreditPoints"
                  width="70"
                  height="25"
                  color="#0f3460"
                  :labels="{ checked: 'Enable', unchecked: 'Disable' }"
                />
              </div>
            </div>
          </div>

          <hr />
          <div class="text-right">
            <!-- Save and Cancel buttons -->
            <button
              class="btn btn-primary btn-sm waves-effect waves-light"
              type="submit"
            >
              Save
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn-light btn-sm waves-effect waves-light"
              v-on:click="closeUpdateUser()"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import Multiselect from "vue-multiselect";
import VeeValidate from "vee-validate";

import CryptoJS from "crypto-js";
import { secretKey } from "../../../../api/global.env";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      projectCollaboratorsList: [
        {
          id: "1",
          name: "Select Collabrator",
        },
      ],
      projectList: [
        {
          id: "1",
          name: "Select Project",
        },
      ],
      username: "",
      email: "",
      password: "",
      githubEmailAddress: "",
      admin: [],
      userProfile: [],
      initialUsername: "",
      formData: {
        email: "",
        username: "",
        githubEmail: "",
        adminId: "",
        id: "",
        docManager: true,
        editor: false,
        publisher: false,
        docMigration: true,
      },
      canViewCreditPoints: false,
      isUserDataFetched: false,
      error: null,
      submitted: false,
      allCollaborators: "",
      optionsCollaboratorlist: [
        {
          value: null,
          text: "Please select an collaborators",
          disabled: true,
        },
      ],
      selectedCollaborators: null,
      activeUserData: "",
      optionsProjectlist: [],
      seletedProject: null,
      appAccess: "",
      DocMigrationOptions: [],
      showPasswordCfrm: false,
      seletedRole: null,
      id: CryptoJS.AES.decrypt(this.$route.params.id, secretKey).toString(
        CryptoJS.enc.Utf8
      ),
    };
  },
  mounted() {
    this.getActiveUserData();
    this.getAllProjects();
  },
  created() {
    VeeValidate.Validator.extend("verify_password", {
      getMessage: () =>
        `The password must contain at least: minimum 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
      validate: (value) => {
        var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
        );
        return strongRegex.test(value);
      },
    });
    if (!this.$store.state.Auth.gitToken) {
      this.$router.push({
        name: "Projects",
      });
    }
  },
  computed: {
    _() {
      return _;
    },
    eyeIconClassNewpass() {
      return this.showPasswordNew ? "mdi mdi-eye-off" : "mdi mdi-eye";
    },
    eyeIconClassCfrm() {
      return this.showPasswordCfrm ? "mdi mdi-eye-off" : "mdi mdi-eye";
    },
  },

  methods: {
    toggleCfrmPasswordVisibility() {
      this.showPasswordCfrm = !this.showPasswordCfrm;
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    getCollabrator() {
      this.$store.getters.client.get(``).then((response) => {
        this.projectCollaboratorsList = response.data;
      });
    },
    getUserProfile() {
      this.isUserDataFetched = false;
      this.$store.getters.client
        .get(`orguser/details?userId=${this.id}`)
        .then((response) => {
          if (response.data) {
            this.formData = response.data;
            this.initialUsername = response.data.username;
            this.canViewCreditPoints = response.data.canViewCreditPoints;
            this.isUserDataFetched = true;
            return Promise.resolve();
          } else {
            // Handle the case where response.data is empty or undefined
            this.messageToast("Error", "danger", "No user profile data found");
            return Promise.reject("No user profile data found");
          }
        })
        .then(async () => {
          let filterCollaborators = this._.filter(this.allCollaborators, {
            gitUsername: this.formData.githubUsername,
          });
          this.selectedCollaborators = filterCollaborators[0].gitUserId;
          this.getProjectBySeletedCollaborators(
            filterCollaborators[0].gitUserId
          );
        })
        .catch((error) => {
          this.isUserDataFetched = true;
          // Handle the error and display a message toast
          this.messageToast(
            "Error",
            "danger",
            error.response ? error.response.data.message : "An error occurred"
          );
        });
    },
    updateUser() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          let loader = this.$loading.show({
            loader: "dots",
          });
          let userIP = await fetch("https://api.ipify.org?format=json")
            .then((x) => x.json())
            .then(async ({ ip }) => {
              return ip;
            });
          let projectArr = [];
          this.seletedProject.forEach((element) => {
            projectArr.push(element.value);
          });
          console.log("📢[updateuser.vue:342]: projectArr: ", projectArr);
          let data = {
            projects: projectArr,
            orgId: this.$store.state.Auth.orgId,
            email: this.formData.email,
            username: this.formData.username,
            canViewCreditPoints: this.canViewCreditPoints,
            githubCollaboratorId: this.selectedCollaborators,
            githubUsername: this.formData.githubUsername,
            githubEmail: this.formData.githubEmail,
            lastLoginId: userIP,
            isActive: true,
            isDeleted: false,
            isSuperUser: false,
            isProfileUpdate: true,
            isRoleAssign: true,
            password: this.password,
          };
          this.$store.getters.client
            .put(`orguser/details?userId=${this.id}`, data)
            .then(() => {
              this.messageToast(
                "Success",
                "success",
                `User profile update successfully`
              );
              this.$store.commit("setActiveUserCount", []);
              this.$store.state.sgitrepodata = [];
              loader.hide();
              setTimeout(() => {
                this.$router.push({
                  path: `/users`,
                });
              }, 1000);
            })
            .catch((error) => {
              this.messageToast("Error", "danger", error.response.data.message);
              loader.hide();
            });
        }
      });
    },
    consolefunc() {},
    closeUpdateUser() {
      this.$router.push({
        path: `/users`,
      });
    },
    async getAllCollaboratorsData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(
          `orgadmin/github/gitcollaborators?orgId=${this.$store.state.Auth.orgId}`
        )
        .then((response) => {
          if (response.data) {
            this.allCollaborators = response.data;
            this.allCollaborators.forEach((element) => {
              let filterCollaborators = this._.filter(this.activeUserData, {
                githubUsername: element.gitUsername,
              });
              if (filterCollaborators.length === 0) {
                this.optionsCollaboratorlist.push({
                  value: element.gitUserId,
                  text: element.gitUsername,
                });
              } else {
                this.optionsCollaboratorlist.push({
                  value: element.gitUserId,
                  text: element.gitUsername,
                  disabled: true,
                });
              }
            });
            this.getUserProfile();
            loader.hide();
          } else {
            // Handle the case where response.data is empty or undefined
            this.messageToast("Error", "danger", "No collaborator data found");
            loader.hide();
          }
        })
        .catch((error) => {
          // Handle the error and display a message toast
          this.messageToast(
            "Error",
            "danger",
            error.response ? error.response.data.message : "An error occurred"
          );
          loader.hide();
        });
    },
    getProjectBySeletedCollaborators(uid) {
      this.seletedProject = [];
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`project/bygituserid?githubUserId=${uid}`)
        .then((response) => {
          console.log("📢[updateuser.vue:440]: response: ", response);
          if (response.data) {
            response.data.forEach((element) => {
              this.seletedProject.push({
                value: element.id.toString(),
                text: element.name,
              });
            });

            console.log(
              "📢[updateuser.vue:439]: response: ",
              this.seletedProject
            );
            return Promise.resolve();
          } else {
            // Handle the case where response.data is empty or undefined
            this.messageToast(
              "Error",
              "danger",
              "No project data found for the collaborator"
            );
            return Promise.reject("No project data found for the collaborator");
          }
        })
        .catch((error) => {
          // Handle the error and display a message toast
          this.messageToast(
            "Error",
            "danger",
            error.response ? error.response.data.message : "An error occurred"
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    async getActiveUserData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`orguser/active/profile?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          if (response.data) {
            this.activeUserData = response.data;
            this.getAllCollaboratorsData();
          } else {
            // Handle the case where response.data is empty or undefined
            this.messageToast("Error", "danger", "No active user data found");
          }
        })
        .catch((error) => {
          // Handle the error and display a message toast
          this.messageToast(
            "Error",
            "danger",
            error.response ? error.response.data.message : "An error occurred"
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    getAllProjects() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.optionsProjectlist = [];
      this.$store.getters.client
        .get(`orgadmin/project?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          response.data.forEach((element) => {
            this.optionsProjectlist.push({
              value: element.projectRepoId,
              text: element.projectName,
            });
          });
          console.log(
            "📢[updateuser.vue:514]: optionsProjectlist: ",
            this.optionsProjectlist
          );
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    },
  },
};
</script>
<style scoped>
.disable-cust-cls {
  background: #e7e7e8 !important;
  color: #838588 !important;
}
.multiselect__tag {
  color: #515a6e !important;
  background-color: none;
}
</style>
