<script>
import CreditDetails from "./creditDetails.vue";
import TransactionHistory from "./transactionHistory.vue";
export default {
  components: {
    CreditDetails,
    TransactionHistory,
  },
};
</script>

<template>
  <b-card>
    <b-tabs
      justified
      nav-class="nav-tabs-custom"
      content-class="text-muted"
      class="btabs-height"
    >
      <b-tab title="Used Credit Details" active>
        <div>
          <div>
            <CreditDetails />
          </div>
        </div>
      </b-tab>
      <b-tab title="Transaction History">
        <div>
          <div>
            <TransactionHistory />
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
