<template>
  <div>
    <!-- Page header component -->
    <PageHeader
      :icon="'mdi mdi-clipboard-multiple h3'"
      :title="title"
      :items="items"
    />
    <!-- Check if the user's status is not 401 (Unauthorized) -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div v-if="this.sgitrepodata.status !== 401">
            <!-- Display loading spinner -->
            <div class="text-center mb-3 p-5" v-if="!this.sgitrepodata.slice">
              <b-spinner variant="primary" v-if="isLoading"></b-spinner>
            </div>
            <div v-if="this.sgitrepodata.slice">
              <!-- Search input and refresh button -->
              <div class="row mb-3">
                <div class="col-md-3 col-sm-12 mb-2 mb-md-0 search-box">
                  <div class="d-flex align-items-center mb-1 position-relative">
                    <input
                      type="text"
                      class="form-control text-primary rounded border-0 cust-input-box"
                      placeholder="Search by project name"
                      id="myInput"
                      v-model="filter"
                    />
                    <i class="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
                <div class="col-md-9 col-sm-12">
                  <div
                    class="d-flex align-items-center justify-content-end mb-1"
                  >
                    <button
                      class="btn btn-primary btn-sm mr-3"
                      @click="toggleNewProject(true)"
                    >
                      <span>
                        <i class="mdi mdi-file-document mdi-16px"></i>
                      </span>
                      Add new project
                    </button>
                    <button
                      class="btn btn-primary btn-sm"
                      @click="projectSync()"
                    >
                      <span>
                        <i class="mdi mdi-folder-refresh mdi-16px"></i>
                      </span>
                      Refresh
                    </button>
                  </div>
                </div>
              </div>
              <!-- Display project cards -->
              <div class="row">
                <div
                  class="col-md-4"
                  v-for="(data, index) in repoSliceList"
                  :key="index"
                >
                  <b-card no-body>
                    <b-card-body class="bcard-body">
                      <b-card-title>
                        <h5
                          class="card-title text-primary d-flex align-items-center"
                        >
                          <span class="mdi mdi-file-document pr-1"></span>
                          <span class="project-name">{{
                            data.projectName
                          }}</span>
                        </h5>
                      </b-card-title>
                      <b-card-sub-title> </b-card-sub-title>
                    </b-card-body>
                    <b-card-body class="bcard-body-border">
                      <b-card-text class="card-text-project text-primary">
                        <span>User</span>
                        {{ data.projectUserCount }}
                      </b-card-text>
                      <hr />
                      <div
                        class="d-flex justify-content-between align-items-center text-primary"
                      >
                        <button
                          class="btn"
                          @click="showDeleteProjectModal(data)"
                        >
                          <span class="mdi mdi-trash-can-outline h4"></span>
                        </button>
                        <div
                          class="view-details-btn"
                          @click="redirectToRepo(data.id)"
                        >
                          <span class="mdi mdi-eye-outline mr-2"></span>View
                          details
                        </div>
                      </div>
                    </b-card-body>
                  </b-card>
                </div>
              </div>
              <!-- Display message when no records match the search -->
              <div
                v-if="!_.isEmpty(sgitrepodata) && repoSliceList.length === 0"
              >
                <div class="row mt-3">
                  <div class="col-md-12">
                    <div class="text-center card border border-primary">
                      <div class="mt-1 maintenance-box">
                        <div class="p-3">
                          <div class="avatar-sm mx-auto">
                            <span
                              class="mdi mdi-magnify-close mdi-36px font-size-24 text-primary"
                            ></span>
                          </div>
                          <h3 class="mt-4">
                            There are no records matching your request
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Pagination -->
              <div class="row mt-3" v-if="!_.isEmpty(sgitrepodata)">
                <div class="col-lg-12">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    class="float-right"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="row">
          <div class="col-md-12">
            <h3>Features included</h3>
            <div v-if="!_.isEmpty(this.appAccess)">
              <div class="row">
                <div class="col-lg-3 col-md-6 mb-3">
                  <div class="d-flex align-items-center">
                    <div>
                      <i
                        class="font-size-26 mdi mdi-file-document-outline text-primary mr-1"
                      ></i>
                    </div>
                    <div>
                      <div class="font-weight-bold">DocManager</div>
                      <div>Manage documents efficiently</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-3">
                  <div class="d-flex align-items-center">
                    <div>
                      <i
                        class="font-size-26 mdi mdi-file-document-edit text-primary mr-1"
                      ></i>
                    </div>
                    <div>
                      <div class="font-weight-bold">DocEditor</div>
                      <div>Edit documents efficiently</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-3">
                  <div class="d-flex align-items-center">
                    <div>
                      <img
                        src="@/assets/file-document-arrow-right.svg"
                        style="height: 14px; color: #0f3460 !important"
                        class="text-primary mr-1"
                        alt
                      />
                    </div>
                    <div>
                      <div class="font-weight-bold">DocPublisher</div>
                      <div>Publish documents efficiently</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-3">
                  <div class="d-flex align-items-center">
                    <div>
                      <i
                        class="font-size-26 mdi mdi-file-replace-outline text-primary mr-1"
                      ></i>
                    </div>
                    <div>
                      <div class="font-weight-bold">DocMigration</div>
                      <div>Migrate documents efficiently</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Apps Card -->
    <!-- Github login modal -->
    <div>
      <div class="my-4 text-center">
        <b-modal
          id="modal-standard"
          :no-close-on-backdrop="true"
          close-disabled="true"
          ref="modal"
          title="Github Login"
          title-class="font-18"
          hide-footer
        >
          <template #modal-header>
            <h5 class="modal-title font-18">Github Login</h5>
          </template>
          <div class="row">
            <div class="col-lg-12">
              <b-card no-body class="text-center">
                <b-card-body>
                  <img src="@/assets/images/octocat.png" height="200" />
                  <b-card-title>
                    <h4 class="card-title">Connect with github</h4>
                  </b-card-title>
                  <b-card-text>
                    To access the project using github need to login in github
                  </b-card-text>
                  <button
                    class="btn btn-primary btn-sm btn-block"
                    @click="syncGithub"
                  >
                    Login
                  </button>
                </b-card-body>
              </b-card>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <!-- Add new project Modal -->
    <div>
      <div class="my-4 text-center">
        <b-modal
          id="modal-standard"
          ref="addNewProjectModal"
          title="Add New Project"
          title-class="font-18"
          hide-footer
        >
          <div class="row">
            <div class="col-lg-12">
              <form
                class="form-horizontal"
                @submit.prevent="addNewGitProject"
                ref="formAddNewProject"
              >
                <div class="form-group">
                  <label for="a_user_password"
                    >Project Name <span>*</span></label
                  >
                  <input
                    v-model="projectName"
                    name="repoName"
                    type="text"
                    class="form-control"
                    placeholder="Enter Project Name"
                  />
                </div>
                <div class="form-group">
                  <label for="a_user_password"
                    >Project Description <span>*</span></label
                  >
                  <input
                    v-model="projectDesc"
                    name="repoDesc"
                    type="text"
                    class="form-control"
                    placeholder="Enter Project Name"
                  />
                </div>

                <div class="text-center">
                  <!-- Save and Cancel Buttons for Password Change -->
                  <button
                    class="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                    :disabled="projectName.length < 3 || projectDesc.length < 3"
                  >
                    Save
                  </button>
                  &nbsp;&nbsp;
                  <b-button
                    class="btn btn-secondry w-md waves-effect waves-light"
                    @click="toggleNewProject(false)"
                    >Cancel</b-button
                  >
                </div>
              </form>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
    <!-- Delete project Modal -->
    <div>
      <div class="my-4 text-center">
        <b-modal
          id="modal-standard"
          ref="deleteProjectModal"
          title="Delete Project"
          title-class="font-18"
          hide-footer
          @hide="resetFields"
        >
          <div class="row">
            <div class="col-lg-12">
              <div class="text-center mb-3">
                <p>Are you sure you want to delete the project?</p>
                <p class="h4">{{ selectedProject?.projectName }}</p>
              </div>
              <form
                class="form-horizontal"
                @submit.prevent="deleteSelectedProject"
                ref="formDeleteProject"
              >
                <div class="form-group">
                  <label for="a_user_password"
                    >To confirm, type "{{ selectedProject?.projectName }}" in
                    the box below</label
                  >
                  <input
                    v-model="confirmRepoName"
                    name="confirmRepoName"
                    type="text"
                    class="form-control"
                  />
                </div>

                <div class="text-center">
                  <button
                    class="btn btn-primary w-md waves-effect waves-light"
                    type="submit"
                    :disabled="
                      !confirmRepoName ||
                      confirmRepoName !== selectedProject?.projectName
                    "
                  >
                    Delete this repository
                  </button>
                </div>
              </form>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import { axios } from "@/api/index.js";
import PageHeader from "@/components/pageheader";
import { URL, ClientId } from "@/api/global.env.js";
import { eventBus } from "../../../main";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../api/global.env";
export default {
  components: {
    PageHeader,
  },
  page: {
    title: "All Repository",
  },
  data() {
    return {
      title: "Projects",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Projects",
          active: true,
        },
      ],
      username: "",
      repocoldata: [],
      gitToken: null,
      apiURL: process.env.VUE_APP_DATABASEURL,
      appgituser: [],
      clientId: `${ClientId}`,
      perPage: 6,
      currentPage: 1,
      orgID: this.$store.state.Auth.orgId,
      appAccess: {},
      isLoading: false,
      orgGithubUser: "",
      orgAccessURL: "",
      orgAccessGithubUsername: "",
      projectList: "",
      filter: "",
      userId: this.$store.state.Auth.userId,
      gitCode: null,
      projectName: "",
      projectDesc: "",
      selectedProject: null,
      confirmRepoName: null,
    };
  },
  computed: {
    ...mapState(["sgitrepodata"]),
    // row count for pagination
    rows() {
      return this.filteredProjects.length;
    },
    repoSliceList() {
      if (this.filteredProjects) {
        const reversedProjects = this.reverseProjects(this.filteredProjects);
        return reversedProjects.slice(
          (this.currentPage - 1) * this.perPage,
          this.currentPage * this.perPage
        );
      } else {
        return null;
      }
    },
    _() {
      return _;
    },
    filteredProjects() {
      return this.sgitrepodata.filter((user) => {
        const filter = this.filter.toUpperCase();
        const hasNameMatch = user.projectName.toUpperCase().includes(filter);
        return hasNameMatch;
      });
    },
  },
  created() {
    eventBus.$emit("update-sidebar", "menuitems.projects.text");
    this.gitToken = this.$store.state.Auth.gitToken;
    this.orgId = this.$store.state.Auth.orgId;
    this.userId = this.$store.state.Auth.userId;
    this.userGithubUsername = this.$store.state.Auth.githubUsername;
    this.getToken();
  },
  mounted() {
    if (
      this.gitToken === "" ||
      this.gitToken === null ||
      this.gitToken === "null" ||
      this.gitToken === undefined ||
      this.gitToken === "undefined"
    ) {
      this.$refs["modal"].show();
    }
    if (!this.$store.state.sgitrepodata.length) {
      this.getGitRepoDetails();
    }
    if (
      this.$store.state.Auth.gitToken &&
      !this.$store.state.sgitrepodata.length
    ) {
      this.getProjects();
    }
    this.getOrgDetails();
  },
  methods: {
    ...mapActions({
      get: "githubApiRequest",
    }),
    syncGithub() {
      window.location.href = `${this.clientId}`;
    },
    reverseProjects(projects) {
      // Use slice to create a shallow copy of the array before reversing
      return projects.slice().reverse();
    },
    redirectToRepo(data) {
      const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
      const encodeddata = encodeURIComponent(encryptedData);
      this.$router.push({
        path: `/github/repo/${encodeddata}`,
      });
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },

    // This method is use to validate get token using github api
    async getToken() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const gitCode = urlParams.get("code");
      this.gitCode = gitCode;
      if (!gitCode) {
        return;
      }
      let userIP = await fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(async ({ ip }) => {
          return ip;
        });
      await this.getOrgDetails();
      this.$store.getters.client
        .get(`/orgadmin/success?code=${gitCode}`)
        .then((res) => {
          if (res.data.userData.login) {
            let domainVerify = null;
            let githubUserNameVerify = null;
            if (res.data.userData.email) {
              // Split orgGithubURL using a forward slash
              let gitUrlSplitArr = res.data.userData.email.split("@");
              // The array github url split initialize on data variable
              domainVerify = gitUrlSplitArr[1];
              githubUserNameVerify = res.data.userData.login;
            }
            if (
              domainVerify === this.orgAccessURL &&
              githubUserNameVerify === this.orgAccessGithubUsername
            ) {
              this.$refs["modal"].hide();
              if (
                res.data.access_token !== null ||
                res.data.access_token !== ""
              ) {
                this.gitToken = res.data.access_token;
                this.username = res.data.userData.login;
              }
              if (res.data.access_token !== "") {
                this.$store.commit("updateGitToken", {
                  gitToken: res.data.access_token,
                });
                this.getGitRepoDetails();
                let gitAuthObj = {
                  userId: this.$store.state.Auth.userId,
                  orgId: this.$store.state.Auth.orgId,
                  username: res.data.userData.login,
                  email: res.data.userData.email,
                  token: res.data.access_token,
                  refreshToken: res.data.access_token,
                  lastLoginIpAddress: userIP,
                };
                this.$store.getters.client
                  .post(`/github/authtoken`, gitAuthObj)
                  .then(() => {})
                  .catch(() => {
                    this.isLoading = false;
                  });
                let loader = this.$loading.show({
                  loader: "dots",
                });
                this.getGitRepoDetails();
                this.$store.getters.client
                  .post(
                    `orgadmin/github/gitcollaborators?orgId=${this.$store.state.Auth.orgId}`
                  )
                  .then(() => {
                    loader.hide();
                  })
                  .catch((error) => {
                    this.messageToast(
                      "Error",
                      "danger",
                      error.response.data.message
                    );
                    loader.hide();
                  });
              }
            } else {
              this.messageToast(
                "Invalid Email or Github Username",
                "danger",
                `You have to login in github using your organization email or registered github username`
              );
            }
          }
          return res;
        })
        .then(() => {
          if (
            this.gitToken === "" ||
            this.gitToken === null ||
            this.gitToken === "null" ||
            this.gitToken === undefined ||
            this.gitToken === "undefined"
          ) {
            this.$refs["modal"].show();
          } else {
            this.$refs["modal"].hide();
          }
          this.$store.getters.client
            .get(`orgadmin/project?orgId=${this.$store.state.Auth.orgId}`)
            .then((response) => {
              this.$store.commit("setGitRepoDetails", response);
              if (response.data.status == 401) {
                this.messageToast(
                  "Error",
                  "danger",
                  `Error on get repolist : ${response.data.message}`
                );
              }
              window.location.href = "/github";
            })
            .catch((err) => {
              this.apiError(err, this.$store);
            });
        })
        .catch(() => {});
    },
    getGitRepoDetails() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`orgadmin/project?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          this.$store.commit("setGitRepoDetails", response);
          loader.hide();
          if (response.data.status == 401) {
            this.messageToast(
              "Error",
              "danger",
              `Error on get repolist : ${response.data.message}`
            );
          }
        })
        .catch(() => {
          loader.hide();
        });
    },
    redirectToCommit() {
      this.$router.push(
        this.$route.query.redirectFrom || {
          name: "repocommit",
        }
      );
    },
    async getOrgDetails() {
      this.isLoading = true;
      this.$store.getters.client
        .get(`/serveradmin/organization/byorgid?orgId=${this.orgID}`)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            // Check if data is available
            this.appAccess = res.data[0];
            var str = res.data[0].orgGithubURL;
            let gitUrlSplitArr = str.split("/");
            this.orgGithubUser = gitUrlSplitArr[3];
            this.orgAccessURL = res.data[0].accessURL;
            this.orgAccessGithubUsername =
              this.$store.state.Auth.githubUsername;
          } else {
            // Handle the case when the response data does not meet your conditions
            this.messageToast(
              "Error",
              "danger",
              "No organization details found"
            );
          }
        })
        .catch(() => {
          // Handle any network errors or other issues that may occur
          // You can also check the error.response if available for specific status codes
          this.messageToast(
            "Error",
            "error",
            "An error occurred while fetching organization details"
          );
        })
        .finally(() => {
          // This block will run regardless of success or failure
          this.isLoading = false;
        });
    },
    getProjects() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      let gitToken = this.$store.state.Auth.gitToken;
      let orgId = this.$store.state.Auth.orgId;
      let userId = this.$store.state.Auth.userId;
      let token = this.$store.state.Auth.token;
      axios({
        method: "Post",
        url: `${URL}api/project/repo/sync?orgId=${orgId}&orgAdminId=${userId}`,
        headers: {
          gitToken: gitToken,
          Authorization: `Bearer ${token}`,
          userId: this.$store.state.Auth.userId,
          orgId: this.$store.state.Auth.orgId,
        },
      })
        .then((res) => {
          res;
          this.messageToast("Success", "success", "Project get Successfully");
          this.getGitRepoDetails();
          loader.hide();
        })
        .catch((error) => {
          this.messageToast("Error", "danger", error.response.data.message);
          loader.hide();
        });
    },
    async projectSync() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .post(`project/repo/sync?orgId=${this.orgID}&orgAdminId=${this.userId}`)
        .then((response) => {
          response;
          this.messageToast("Success", "success", "Project Sync Successfully");
          loader.hide();
          this.getGitRepoDetails();
        })
        .catch((error) => {
          this.messageToast("Error", "danger", error.response.data.message);
          loader.hide();
        });
    },

    toggleNewProject(status) {
      if (status === true) {
        this.$refs["addNewProjectModal"].show();
      } else {
        this.$refs["addNewProjectModal"].hide();
      }
    },

    addNewGitProject() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .post(`/project/repo/create`, {
          repoName: this.projectName,
          repoDesc: this.projectDesc,
        })
        .then((res) => {
          console.log("📢[index.vue:704]: res: ", res);
          // Resetting Values
          this.$refs.formAddNewProject.reset();
          this.$refs["addNewProjectModal"].hide();
          this.messageToast(
            "Success",
            "success",
            res.data.message || "Project created successfully."
          );
          this.projectSync();
        })
        .catch((error) => {
          console.log("📢[index.vue:715]: error: ", error);
          this.messageToast(
            "Error",
            "danger",
            error.response.data.message || "Failed to create a new project."
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    showDeleteProjectModal(project) {
      console.log("📢[index.vue:788]: project: ", project);
      this.selectedProject = project;
      this.$refs["deleteProjectModal"].show();
    },
    deleteSelectedProject() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .delete(
          `/project/repo/remove?id=${this.selectedProject.id}&confirmRepoName=${this.confirmRepoName}`
        )
        .then((res) => {
          console.log("📢[index.vue:704]: res: ", res);
          // Resetting Values
          this.$refs.formDeleteProject.reset();
          this.$refs["deleteProjectModal"].hide();
          this.confirmRepoName = null;
          this.selectedProject = null;
          this.getGitRepoDetails();
          this.messageToast(
            "Success",
            "success",
            res.data.message || "Project deleted successfully."
          );
        })
        .catch((error) => {
          console.log("📢[index.vue:715]: error: ", error);
          this.messageToast(
            "Error",
            "danger",
            error.response.data.message || "Failed to delete a project."
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    resetFields() {
      this.confirmRepoName = null;
      this.selectedProject = null;
    },
  },
};
</script>
<style scoped>
.project-name {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.custom-heading {
  font-size: 16px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.5px;
  text-align: left;
}
hr {
  margin-bottom: 0.2 rem !important;
}
.view-details-btn {
  cursor: pointer;
}
.custom-btn {
  float: right;
}
.pj-action-card {
  padding: 20;
}
.bcard-body {
  background-color: #cfdee9;
  border-radius: 4px 4px 0px 0px;
}

.refresh-btn {
  display: inline;
  float: right;
  margin-left: auto;
}
.card.pj-action-card > .card-body {
  padding: 10px 15px !important;
}
.card.pj-action-card > .card-body > .card-title {
  margin: 0px !important;
}
.rl-title {
  color: #021559;
  font-size: 20px;
  vertical-align: text-bottom;
  padding-left: 10px;
  font-weight: 600;
  margin-bottom: 1px;
}
.rl-subtitle {
  color: #021559;
  padding-left: 12px;
  margin-bottom: 0px;
}
.cnt-main-cls1 {
  padding: 10px 10px;
}
.cnt-main-cls2 {
  padding: 10px 0px;
}
.badge-soft-warning-project {
  background-color: rgba(252, 185, 44, 0.18);
  color: #000 !important;
  font-weight: 700 !important;
  font-size: 0.9rem !important;
}
.badge-soft-succes-project {
  background-color: rgba(28, 187, 140, 0.18);
  color: #000 !important;
  font-weight: 700 !important;
  font-size: 0.9rem !important;
}
.card-text-project {
  margin-bottom: 0.5rem;
}
.cust-input-box {
  border: 1px solid #ced4da !important;
  background-color: #ffffff !important;
}
</style>
