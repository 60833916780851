var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{ref:"formUpdateUser",staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.updateUser.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(0),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.username),expression:"formData.username"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_username.$error,
                },attrs:{"name":"updatef_username","type":"text","id":"updatef_username","placeholder":"Enter username"},domProps:{"value":(_vm.formData.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "username", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_username')),expression:"errors.has('updatef_username')"}],staticClass:"text-danger text-sm"},[_vm._v(" The username field is required ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('b-form-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_collaborators.$error,
                },attrs:{"options":_vm.optionsCollaboratorlist,"name":"updatef_collaborators","type":"text","id":"updatef_collaborators","placeholder":"Select collaborators","disabled":""},model:{value:(_vm.selectedCollaborators),callback:function ($$v) {_vm.selectedCollaborators=$$v},expression:"selectedCollaborators"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_collaborators')),expression:"errors.has('updatef_collaborators')"}],staticClass:"text-danger text-sm"},[_vm._v(" The Collaborators field is required ")])],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(2),_c('multiselect',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.addf_projects.$error,
                },attrs:{"name":"updatef_projects","id":"updatef_projects","tag-placeholder":"Add this as a new tag","placeholder":"Please select a project","label":"text","track-by":"value","options":_vm.optionsProjectlist,"multiple":true,"max-height":150},scopedSlots:_vm._u([{key:"selection",fn:function({ values, search, isOpen }){return [(values.length)?_c('span',{directives:[{name:"show",rawName:"v-show",value:(!isOpen),expression:"!isOpen"}],staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" project selected")]):_vm._e()]}}]),model:{value:(_vm.seletedProject),callback:function ($$v) {_vm.seletedProject=$$v},expression:"seletedProject"}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_projects')),expression:"errors.has('updatef_projects')"}],staticClass:"text-danger text-sm"},[_vm._v(" The projects field is required ")])],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(3),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_email.$error,
                },attrs:{"name":"updatef_email","type":"text","id":"updatef_email","placeholder":"Enter email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "email", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_email')),expression:"errors.has('updatef_email')"}],staticClass:"text-danger text-sm"},[_vm._v(" The email field is required ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(4),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.githubUsername),expression:"formData.githubUsername"}],staticClass:"form-control disable-cust-cls",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_githubUsername.$error,
                },attrs:{"name":"updatef_githubUsername","type":"text","id":"updatef_githubUsername","placeholder":"Enter GitHub Username","disabled":""},domProps:{"value":(_vm.formData.githubUsername)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "githubUsername", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_githubUsername')),expression:"errors.has('updatef_githubUsername')"}],staticClass:"text-danger text-sm"},[_vm._v(" The GitHub username field is required ")])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(5),_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.formData.githubEmail),expression:"formData.githubEmail"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.updatef_githubEmail.$error,
                },attrs:{"name":"updatef_githubEmail","type":"text","id":"updatef_githubEmail","placeholder":"Enter GitHub email"},domProps:{"value":(_vm.formData.githubEmail)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "githubEmail", $event.target.value)}}}),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('updatef_githubEmail')),expression:"errors.has('updatef_githubEmail')"}],staticClass:"text-danger text-sm"},[_vm._v(" The GitHub email address field is required ")])])]),(!_vm.initialUsername)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(6),_c('div',{staticClass:"input-group"},[((_vm.showPasswordCfrm ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|verify_password'),expression:"'required|verify_password'"},{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"addf_password",staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.addf_password.$error,
                  },attrs:{"autocomplete":"new-password","name":"addf_password","id":"addf_password","placeholder":"Enter password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.showPasswordCfrm ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|verify_password'),expression:"'required|verify_password'"},{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"addf_password",staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.addf_password.$error,
                  },attrs:{"autocomplete":"new-password","name":"addf_password","id":"addf_password","placeholder":"Enter password","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required|verify_password'),expression:"'required|verify_password'"},{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"addf_password",staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.addf_password.$error,
                  },attrs:{"autocomplete":"new-password","name":"addf_password","id":"addf_password","placeholder":"Enter password","type":_vm.showPasswordCfrm ? 'text' : 'password'},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('div',{staticClass:"btn btn-outline-light",attrs:{"type":"button"},on:{"click":function($event){return _vm.toggleCfrmPasswordVisibility()}}},[_c('i',{class:_vm.eyeIconClassCfrm})])])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.errors.has('addf_password') &&
                  _vm._.find(_vm.errors.items, { field: 'addf_password' }).rule ==
                    'required'
                ),expression:"\n                  errors.has('addf_password') &&\n                  _.find(errors.items, { field: 'addf_password' }).rule ==\n                    'required'\n                "}],staticClass:"text-danger text-sm"},[_c('span',[_vm._v("The password field is required")])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.errors.has('addf_password') &&
                  _vm._.find(_vm.errors.items, { field: 'addf_password' }).rule ==
                    'verify_password'
                ),expression:"\n                  errors.has('addf_password') &&\n                  _.find(errors.items, { field: 'addf_password' }).rule ==\n                    'verify_password'\n                "}],staticClass:"text-danger text-sm"},[_vm._v(" "+_vm._s(_vm.errors.first("addf_password"))+" ")])])]):_vm._e(),(_vm.isUserDataFetched)?_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_vm._m(7),_c('toggle-button',{staticClass:"mt-2",attrs:{"value":_vm.canViewCreditPoints,"width":"70","height":"25","color":"#0f3460","labels":{ checked: 'Enable', unchecked: 'Disable' }},on:{"change":function($event){_vm.canViewCreditPoints = !_vm.canViewCreditPoints}}})],1)]):_vm._e()]),_c('hr'),_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary btn-sm waves-effect waves-light",attrs:{"type":"submit"}},[_vm._v(" Save ")]),_vm._v("    "),_c('button',{staticClass:"btn btn-light btn-sm waves-effect waves-light",on:{"click":function($event){return _vm.closeUpdateUser()}}},[_vm._v(" Cancel ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_username"}},[_vm._v("Username "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_collaborators"}},[_vm._v("Collaborators "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_projects"}},[_vm._v("Projects "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_email"}},[_vm._v("Email Address "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_githubUsername"}},[_vm._v("Github Username "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"updatef_githubEmail"}},[_vm._v("GitHub Email Address "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"addf_password"}},[_vm._v("Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"mr-3"},[_vm._v("View remaining credits "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }