<template>
  <div>
    <!-- User Addition Form -->
    <b-container>
      <b-card>
        <form
          class="form-horizontal"
          @submit.prevent="addUser"
          ref="formAddUser"
        >
          <!-- Collaborators Selection -->
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="addf_username"
                  >Username <span class="text-danger">*</span></label
                >
                <!-- Input field for Username -->
                <input
                  v-validate="'required'"
                  v-model="formData.username"
                  name="addf_username"
                  type="text"
                  class="form-control"
                  id="addf_username"
                  placeholder="Enter username"
                  :class="{
                    'is-invalid': submitted && $v.addf_username.$error,
                  }"
                />
                <!-- Validation message for Username -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_username')"
                >
                  The username field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Projects Selection -->
              <div class="form-group">
                <label for="addf_projects"
                  >Projects <span class="text-danger">*</span></label
                >
                <!-- Multiselect input for Projects -->
                <multiselect
                  v-validate="'required'"
                  name="addf_projects"
                  id="addf_projects"
                  :class="{
                    'is-invalid': submitted && $v.addf_projects.$error,
                  }"
                  v-model="seletedProject"
                  tag-placeholder="Add this as a new tag"
                  placeholder="Please select a project"
                  label="text"
                  track-by="value"
                  :options="optionsProjectlist"
                  :multiple="true"
                >
                  <template #selection="{ values, search, isOpen }">
                    <span
                      class="multiselect__single"
                      v-if="values.length"
                      v-show="!isOpen"
                      >{{ values.length }} project selected</span
                    >
                  </template></multiselect
                >
                <!-- Validation message for Projects -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_projects')"
                >
                  The projects field is required
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- Email Input -->
              <div class="form-group">
                <label for="addf_email"
                  >Email Address <span class="text-danger">*</span></label
                >
                <!-- Input field for Email Address -->
                <input
                  v-validate="'required'"
                  v-model="formData.email"
                  name="addf_email"
                  type="text"
                  class="form-control"
                  id="addf_email"
                  placeholder="Enter email"
                  :class="{
                    'is-invalid': submitted && $v.addf_email.$error,
                  }"
                />
                <!-- Validation message for Email Address -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_email')"
                >
                  The email field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Password Input -->
              <div class="form-group">
                <label for="addf_password"
                  >Password <span class="text-danger">*</span></label
                >
                <div class="input-group">
                  <!-- Input field for Password with toggle button -->
                  <input
                    v-validate="'required|verify_password'"
                    v-model="password"
                    name="addf_password"
                    :type="showPasswordCfrm ? 'text' : 'password'"
                    class="form-control"
                    id="addf_password"
                    placeholder="Enter password"
                    :class="{
                      'is-invalid': submitted && $v.addf_password.$error,
                    }"
                    ref="addf_password"
                  />
                  <!-- Password visibility toggle button -->
                  <div class="input-group-append">
                    <div
                      class="btn btn-outline-light"
                      type="button"
                      @click="toggleCfrmPasswordVisibility()"
                    >
                      <i :class="eyeIconClassCfrm"></i>
                    </div>
                  </div>
                </div>
                <!-- Validation messages for Password -->
                <p
                  class="text-danger text-sm"
                  v-show="
                    errors.has('addf_password') &&
                    _.find(errors.items, { field: 'addf_password' }).rule ==
                      'required'
                  "
                >
                  <span>The password field is required</span>
                </p>
                <p
                  class="text-danger text-sm"
                  v-show="
                    errors.has('addf_password') &&
                    _.find(errors.items, { field: 'addf_password' }).rule ==
                      'verify_password'
                  "
                >
                  {{ errors.first("addf_password") }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- Github Username Input -->
              <div class="form-group">
                <label for="addf_githubUsername"
                  >Github Username <span class="text-danger">*</span></label
                >
                <!-- Input field for Github Username (disabled) -->
                <input
                  v-validate="'required'"
                  v-model="formData.githubUsername"
                  name="addf_githubUsername"
                  type="text"
                  class="form-control"
                  id="addf_githubUsername"
                  placeholder="Github Username"
                  :class="{
                    'is-invalid': submitted && $v.addf_githubUsername.$error,
                  }"
                />
                <!-- Validation message for Github Username -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_githubUsername')"
                >
                  The GitHub username field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Github Email Address Input -->
              <div class="form-group">
                <label for="addf_githubEmail"
                  >Github Email Address
                  <span class="text-danger">*</span></label
                >
                <!-- Input field for Github Email Address -->
                <input
                  v-validate="'required'"
                  v-model="formData.githubEmail"
                  name="addf_githubEmail"
                  type="text"
                  class="form-control"
                  id="addf_githubEmail"
                  placeholder="Enter GitHub email address"
                  :class="{
                    'is-invalid': submitted && $v.addf_githubEmail.$error,
                  }"
                />
                <!-- Validation message for Github Email Address -->
                <p
                  class="text-danger text-sm"
                  v-show="errors.has('addf_githubEmail')"
                >
                  The GitHub email field is required
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label class="mr-3"
                  >View remaining credits
                  <span class="text-danger">*</span></label
                >
                <toggle-button
                  class="mt-2"
                  :value="canViewCreditPoints"
                  @change="canViewCreditPoints = !canViewCreditPoints"
                  width="70"
                  height="25"
                  color="#0f3460"
                  :labels="{ checked: 'Enable', unchecked: 'Disable' }"
                />
              </div>
            </div>
          </div>

          <hr />
          <!-- Save and Cancel Buttons -->
          <div class="text-right">
            <button
              class="btn btn-sm btn-primary waves-effect waves-light"
              type="submit"
            >
              Save
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-default btn-sm" v-on:click="closeAddUser()">
              Cancel
            </button>
          </div>
        </form>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import VeeValidate from "vee-validate";
import Multiselect from "vue-multiselect";
import _ from "lodash";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      projectCollaboratorsList: [
        {
          id: "1",
          name: "Select Collabrator",
        },
      ],
      projectList: [
        {
          id: "1",
          name: "Select Project",
        },
      ],
      password: "",
      formData: {
        seletedProject: null,
        email: "",
        seletedRole: [],
        username: "",
        githubUsername: "",
        githubEmail: "",
        id: "",
        password: "",
      },
      seletedProject: null,
      selectedAdmin: null,
      canViewCreditPoints: false,
      error: null,
      selectedMigrationOption: null,
      submitted: false,

      optionsProjectlist: [],

      activeUserData: "",
      inactiveUserData: "",
      showPasswordCfrm: false,
    };
  },
  mounted() {
    this.getAllProjects();
  },
  computed: {
    _() {
      return _;
    },
    eyeIconClassNewpass() {
      return this.showPasswordNew ? "mdi mdi-eye-off" : "mdi mdi-eye";
    },
    eyeIconClassCfrm() {
      return this.showPasswordCfrm ? "mdi mdi-eye-off" : "mdi mdi-eye";
    },
  },

  created() {
    VeeValidate.Validator.extend("verify_password", {
      getMessage: () =>
        `The password must contain at least: minimum 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,
      validate: (value) => {
        var strongRegex = new RegExp(
          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})"
        );
        return strongRegex.test(value);
      },
    });
    if (!this.$store.state.Auth.gitToken) {
      this.$router.push({
        name: "Projects",
      });
    }
  },
  methods: {
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    toggleCfrmPasswordVisibility() {
      this.showPasswordCfrm = !this.showPasswordCfrm;
    },

    async addUser() {
      this.$validator
        .validateAll()
        .then(async (result) => {
          if (result) {
            let userIP = await fetch("https://api.ipify.org?format=json")
              .then((x) => x.json())
              .then(async ({ ip }) => {
                return ip;
              });
            let projectArr = [];
            this.seletedProject.forEach((element) => {
              projectArr.push(element.value);
            });
            let data = {
              projects: projectArr,
              orgId: this.$store.state.Auth.orgId,
              email: this.formData.email,
              username: this.formData.username,
              password: this.password,
              githubUsername: this.formData.githubUsername,
              githubEmail: this.formData.githubEmail,
              lastLoginId: userIP,
              isActive: true,
              isDeleted: false,
              isSuperUser: false,
              canViewCreditPoints: this.canViewCreditPoints,
            };

            let loader = this.$loading.show({
              loader: "dots",
            });
            this.$store.getters.client
              .put(`orguser/profileupdate`, data)
              .then(() => {
                this.messageToast(
                  "Success",
                  "success",
                  `User added successfully`
                );
                loader.hide();
                this.$store.commit("setActiveUserCount", []);
                this.$store.state.sgitrepodata = [];

                setTimeout(() => {
                  this.$router.push({
                    path: `/users`,
                  });
                }, 1000);
              })
              .catch((error) => {
                this.messageToast(
                  "Error",
                  "danger",
                  error.response.data.message
                );
                loader.hide();
              });
          }
        })
        .catch((err) => console.log(err));
    },

    getAllProjects() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.optionsProjectlist = [];
      this.$store.getters.client
        .get(`orgadmin/project?orgId=${this.$store.state.Auth.orgId}`)
        .then((response) => {
          response.data.forEach((element) => {
            this.optionsProjectlist.push({
              value: element.projectRepoId,
              text: element.projectName,
            });
          });
          loader.hide();
        })
        .catch(() => {
          loader.hide();
        });
    },

    closeAddUser() {
      this.$router.push({
        path: `/users`,
      });
    },

    addUseFormReset() {
      // Resetting Values
      (this.seletedRole = []),
        (this.selectedAdmin = null),
        (this.formData.username = ""),
        (this.formData.email = ""),
        (this.password = ""),
        (this.formData.githubEmail = "");
      this.$refs.formAddUser.reset();
    },
  },
};
</script>
<style scoped>
.btn-default {
  border: 2px solid #dcdee2;
}
option:disabled {
  color: #b6b6be !important;
}
.disable-cust-cls {
  background: #e7e7e8 !important;
  color: #838588 !important;
}
</style>
