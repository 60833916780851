<template>
  <div>
    <PageHeader :icon="'mdi mdi-bell h3'" :title="title" :items="items" />
    <div>
      <!-- Main Card -->
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <!-- Filter and Per Page Options -->

              <div class="row mt-2 d-flex justify-content-between mx-3">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
                <div
                  class="d-flex align-items-center justify-content-between mb-3"
                >
                  <div class="search-box mr-3">
                    <div class="position-relative">
                      <input
                        type="text"
                        class="form-control text-primary rounded custom-input-box"
                        placeholder="Search"
                        id="myInput"
                        v-model="filter"
                      />
                      <i class="mdi mdi-magnify search-icon"></i>
                    </div>
                  </div>
                  <!-- Buttons -->
                  <b-button
                    size="sm"
                    class="export-button mr-2"
                    variant="light"
                    @click="exportToExcel"
                    >Export to Excel</b-button
                  >
                  <b-button
                    size="sm"
                    class="export-button"
                    variant="light"
                    @click="exportToCSV"
                    >Export to CSV</b-button
                  >
                </div>
              </div>
              <!-- Data Table -->
              <div class="table-responsive mb-0">
                <b-table
                  :items="activityData"
                  :fields="colfields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  :filter-function="filterTable"
                  ref="adminTable"
                  show-empty
                >
                  <!-- Custom cell rendering for createdAt -->
                  <template #cell(createdAt)="row">
                    {{
                      moment(row.item.createdAt).format("DD-MMMM-YYYY hh:mm A")
                    }}
                  </template>
                </b-table>
              </div>
              <!-- Pagination -->
              <div class="col-12 border-top pt-3">
                <div class="d-flex justify-content-end">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="filteredData.length"
                    :per-page="perPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import PageHeader from "@/components/pageheader";
import { handleApiError } from "../../../apiErrorHandler.js";
import _ from "lodash";
import * as XLSX from "xlsx";
import { eventBus } from "../../../main";
import { axios } from "@/api/index.js";
import { URL } from "@/api/global.env";
export default {
  data() {
    return {
      activityData: [],
      filteredData: [],
      title: "Recent Notifications",
      items: [
        {
          text: "Dashboard",
          href: `/dashboard`,
        },
        {
          text: "Recent Notifications ",
          active: true,
        },
      ],
      userId: this.$store.state.Auth.userId,
      orgId: this.$store.state.Auth.orgId,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: true,
      colfields: this.generateTableColumns(),
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Number of Releases",
            backgroundColor: "#f87979",
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  components: {
    PageHeader,
  },
  computed: {
    rows() {
      return this.activityData.length;
    },
    _() {
      return _;
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.perPage);
    },
  },
  watch: {
    perPage: {
      handler() {
        this.currentPage = 1;
      },
    },
    activityData: {
      handler() {
        this.filteredData = this.activityData;
      },
    },
    filter: {
      handler() {
        this.filteredData = this.activityData?.filter((data) =>
          data.projectName.toUpperCase().includes(this.filter?.toUpperCase())
        );
      },
    },
  },
  created() {
    eventBus.$emit("update-sidebar", "menuitems.dashboard.text");
    if (this.$store.state.Auth.recentNotification.length) {
      this.activityData = this.$store.state.Auth.recentNotification;
    } else {
      this.getNotification();
    }
    this.prepareChartData();
  },
  methods: {
    moment,
    onFiltered(filteredItems) {
      // Update the totalRows to the length of the filtered items
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    prepareChartData() {
      const projects = {};
      // Count releases per project
      this.data.forEach((release) => {
        const projectName = release.projectName;
        projects[projectName] = (projects[projectName] || 0) + 1;
      });
      // Populate chart data
      this.chartData.labels = Object.keys(projects);
      this.chartData.datasets[0].data = Object.values(projects);
      // Update the chart
      this.$refs.barChart._chart.update();
    },
    generateTableColumns() {
      return [
        {
          key: "message",
          label: "Commit",
          sortable: true,
        },
        {
          key: "projectName",
          label: "Project Name",
          sortable: true,
        },
        {
          key: "commiter.name",
          label: "Committed By",
          sortable: true,
        },
        {
          key: "commitDate",
          label: "Commit Date",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) =>
            moment(item.commiter.date).format("DD-MMMM-YYYY hh:mm A"),
        },
      ];
    },
    // This method is use to generate error or success message
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    getNotification() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      const gitToken = this.$store.state.Auth.gitToken;
      const orgId = this.$store.state.Auth.orgId;
      const token = this.$store.state.Auth.token;
      const userId = this.$store.state.Auth.userId;
      axios({
        method: "get",
        url: `${URL}api/orgadmin/notification/24hrsCommit?orgId=${orgId}`,
        headers: {
          gitToken,
          Authorization: `Bearer ${token}`,
          userId,
          orgId,
        },
      })
        .then((res) => {
          this.showSpinner = false;
          loader.hide();
          this.$store.commit("setrecentNotification", res.data);
          this.activityData = res.data;
        })
        .catch((error) => {
          this.showSpinner = false;
          loader.hide();
          handleApiError(error);
        });
    },
    exportToExcel() {
      const dataToExport = this.filteredData.map((item) => ({
        Commit: item.message,
        "Project Name": item.projectName,
        "Committed By": item.commiter.name,
        "Committed At": moment(item.commiter.date).format(
          "DD-MMMM-YYYY hh:mm A"
        ),
      }));
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the workbook as an Excel file
      XLSX.writeFile(wb, "table_data.xlsx");
    },
    exportToCSV() {
      const dataToExport = this.filteredData.map((item) => ({
        Commit: item.message,
        "Project Name": item.projectName,
        "Committed By": item.commiter.name,
        "Committed At": moment(item.commiter.date).format(
          "DD-MMMM-YYYY hh:mm A"
        ),
      }));
      const ws = XLSX.utils.json_to_sheet(dataToExport);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      // Save the workbook as a CSV file
      XLSX.writeFile(wb, "table_data.csv", {
        bookType: "csv",
      });
    },
    filterTable(row, filter) {
      const filterUpperCase = filter.toUpperCase();
      const hasNameMatch = row.projectName
        .toUpperCase()
        .includes(filterUpperCase);
      return hasNameMatch;
    },
  },
};
</script>
<style>
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .row-count-cust-cls {
    margin-bottom: 10px;
  }
  .row-pagination-cust-cls {
    margin-top: 20px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .row-count-cust-cls ul {
    margin-bottom: 10px;
    text-align: left !important;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
