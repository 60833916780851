<template>
  <div>
    <!-- Page Header Component -->
    <PageHeader
      :icon="'mdi mdi-office-building h3'"
      :title="title"
      :items="items"
    />
    <!-- Main Content Row -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" v-if="!_.isEmpty(this.orgDetails)">
            <!-- Organization Details -->
            <!-- Accordion for Subscription Plan -->
            <div role="tablist">
              <b-card no-body class="mb-2 shadow custom-card-border">
                <b-card-header
                  class="custom-b-card-header"
                  header-tag="header"
                  role="tab"
                >
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-4
                      class="text-dark"
                      href="javascript: void(0);"
                      >General details</a
                    >
                  </h6>
                </b-card-header>
                <b-collapse
                  id="accordion-4"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p>
                        <b>Organization Id :</b> {{ this.orgDetails.customId }}
                      </p>
                      <p>
                        <b>Organization Name : </b>{{ this.orgDetails.name }}
                      </p>
                      <p>
                        <b>Organization Email Address : </b
                        >{{ this.orgDetails.email }}
                      </p>
                      <p>
                        <b>Subdomain Name : </b> {{ this.orgDetails.accessURL }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <!-- Accordion for User Details -->
              <b-card no-body class="mb-2 shadow custom-card-border">
                <b-card-header
                  class="custom-b-card-header"
                  header-tag="header"
                  role="tab"
                >
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-2
                      class="text-dark"
                      href="javascript: void(0);"
                      >User Details</a
                    >
                  </h6>
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p>
                        <b>Users :</b>
                        {{
                          this.orgUserActive.length +
                          this.orgUserInactive.length
                        }}
                      </p>
                      <!-- <p><b>Admin : </b> {{ this.orgAdmin.length }}</p> -->
                      <p>
                        <b>Active Users : </b> {{ this.orgUserActive.length }}
                      </p>
                      <p>
                        <b>Inactive Users : </b>
                        {{ this.orgUserInactive.length }}
                      </p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <!-- Accordion for VM's Specification -->
              <b-card no-body class="mb-2 shadow custom-card-border">
                <b-card-header
                  class="custom-b-card-header"
                  header-tag="header"
                  role="tab"
                >
                  <h6 class="m-0">
                    <a
                      v-b-toggle.accordion-3
                      class="text-dark"
                      href="javascript: void(0);"
                      >Vm's Specification</a
                    >
                  </h6>
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <p><b>Vm Count : </b> 01</p>
                      <p><b>CPU : </b> 1 vCPU</p>
                      <p><b>Memory : </b> 614 MB</p>
                      <p><b>Basic Charges : </b> 01</p>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { mapState } from "vuex";
import PageHeader from "@/components/pageheader";
import { eventBus } from "../../../main";
import { handleApiError } from "../../../apiErrorHandler";
export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      title: "Organization",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Organization",
          active: true,
        },
      ],
      orgDetails: [],
      orgUserDetails: [],
      orgSubscriptionPlanDetails: [],
      orgAdmin: [],
      orgUserActive: [],
      orgUserInactive: [],
    };
  },
  computed: {
    ...mapState(["gorgdetails"]),
    _() {
      return _;
    },
  },
  created() {
    eventBus.$emit("update-sidebar", "menuitems.organization.text");
    if (!this.$store.state.Auth.gitToken) {
      this.$router.push({
        name: "Projects",
      });
    }
    if (
      this.$store.state.Auth.orgUserDetails.length &&
      this.$store.state.Auth.activeUserData.length &&
      this.$store.state.Auth.inActiveUserData.length &&
      this.$store.state.Auth.orgAdminDetails.length
    ) {
      this.orgUserDetails = this.$store.state.Auth.orgUserDetails;
      this.orgUserActive = this.$store.state.Auth.activeUserData;
      this.orgUserInactive = this.$store.state.Auth.inActiveUserData;
      this.orgAdmin = this.$store.state.Auth.orgAdminDetails;
    } else {
      this.getOrgUserDetails();
    }
    if (
      Object.keys(this.$store.state.Auth.orgDetails).length > 0 &&
      Object.keys(this.$store.state.Auth.subPlanDtls).length > 0
    ) {
      this.orgDetails = this.$store.state.Auth.orgDetails;
      this.orgSubscriptionPlanDetails = this.$store.state.Auth.subPlanDtls;
    } else {
      this.getorgDetails();
    }
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    getFormattedDate() {
      const timestamp = this.orgSubscriptionPlanDetails.planExpiry;
      const dateObject = new Date(timestamp);
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1;
      const day = dateObject.getDate();
      return `${year}-${month}-${day}`;
    },
    getFormattedDatesubscribedAt() {
      const timestamp = this.orgSubscriptionPlanDetails.subscribedAt;
      const dateObject = new Date(timestamp);
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1;
      const day = dateObject.getDate();
      return `${year}-${month}-${day}`;
    },
    async getorgDetails() {
      let OrgID = this.$store.state.Auth.orgId;
      let loader = this.$loading.show({
        loader: "dots",
      });
      return this.$store.getters.client
        .get(`serveradmin/organization/byorgid?orgId=${OrgID}`)
        .then((orgDetailsResponse) => {
          if (orgDetailsResponse.data && orgDetailsResponse.data.length > 0) {
            this.orgDetails = orgDetailsResponse.data[0];
            this.$store.commit("setOrgDetails", this.orgDetails);
            let subscriptionRef = this.orgDetails.subscriptionRef[0];
            return this.$store.getters.client.get(
              `orgadmin/subscriptionDetails?subscriptionId=${subscriptionRef}`
            );
          } else {
            this.messageToast(
              "Error",
              "danger",
              "No organization details found"
            );
          }
        })
        .then((orgSubscriptionPlanDetailsResponse) => {
          if (orgSubscriptionPlanDetailsResponse.data) {
            this.orgSubscriptionPlanDetails =
              orgSubscriptionPlanDetailsResponse.data;
            this.$store.commit(
              "setSubPlanDtls",
              this.orgSubscriptionPlanDetails
            );
          } else {
            this.messageToast(
              "Error",
              "danger",
              "No subscription plan details found"
            );
          }
        })
        .catch((err) => {
          this.messageToast("Error", "danger", err.response.data.error);
        })
        .finally(() => {
          loader.hide();
        });
    },
    getOrgUserDetails() {
      let OrgID = this.$store.state.Auth.orgId;
      let loader = this.$loading.show({
        loader: "dots",
      });
      // Fetch org user details
      this.$store.getters.client
        .get(`orguser/byorgid?orgId=${OrgID}`)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.orgUserDetails = response.data;
            this.$store.commit("setOrgUserDetails", this.orgUserDetails);
          }
          loader.hide();
        })
        .catch((err) => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            `Error on get org user details: ${err.response.data.message}`
          );
        });
      // Fetch active user profiles
      this.$store.getters.client
        .get(`orguser/active/profile?orgId=${OrgID}`)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.orgUserActive = response.data;
            this.$store.commit("setActiveUserCount", this.orgUserActive);
          }
        })
        .catch((err) => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            `Error on get active user profiles: ${err.response.data.message}`
          );
        });
      // Fetch inactive user profiles
      this.$store.getters.client
        .get(`orguser/inactive/profile?orgId=${OrgID}`)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.orgUserInactive = response.data;
            this.$store.commit("setInactiveUserCount", this.orgUserInactive);
          }
        })
        .catch((err) => {
          loader.hide();
          this.messageToast(
            "Error",
            "danger",
            `Error on get inactive user profiles: ${err.response.data.message}`
          );
        });
      // Fetch org admin details
      this.$store.getters.client
        .get(`orgadmin`)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.orgAdmin = response.data;
            this.$store.commit("setOrgAdmin", this.orgAdmin);
          }
          loader.hide();
        })
        .catch((err) => {
          loader.hide();
          handleApiError(err);
          this.messageToast(
            "Error",
            "danger",
            `Error on get org admin details: ${err.response.data.message}`
          );
        });
    },
  },
};
</script>
<style>
.custom-b-card-header {
  background-color: #cfdee9 !important;
  color: rgba(23, 35, 61, 1) !important;
  padding: 18px, 16px, 18px, 16px !important;
  border-radius: 4px !important;
  border: 1px !important;
  gap: 10px !important;
}

.custom-card-border {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  border-radius: 4 !important;
}
</style>
