import { render, staticRenderFns } from "./widget.vue?vue&type=template&id=f91fda28&scoped=true"
import script from "./widget.vue?vue&type=script&lang=js"
export * from "./widget.vue?vue&type=script&lang=js"
import style0 from "./widget.vue?vue&type=style&index=0&id=f91fda28&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f91fda28",
  null
  
)

export default component.exports